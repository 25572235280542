import React, { Component } from 'react'
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import MediaQuery from 'react-responsive';

import Video1 from "./../../assets/videos/video1.mp4"
import Video2 from "./../../assets/videos/video2.mp4"
import ResponsiveVideo1 from "./../../assets/images/vivepiqe/image_responsive_video1.png"
import Bubles1 from "./../../assets/svg/bubleve1.svg"
import Bubles2 from "./../../assets/svg/bubleve2.svg"
import Bubles3 from "./../../assets/svg/bubleve3.svg"
import Bubles4 from "./../../assets/svg/bubleve4.svg"
import Bubles5 from "./../../assets/svg/bubleve5.svg"

import DelimiterTop from "./../../assets/images/vivepiqe/delimiter_top.png"
import DelimiterBottom from "./../../assets/images/vivepiqe/delimiter_bottom.png"

import sectionImage2 from "./../../assets/images/vivepiqe/landscape.png"

import BussinesEpiqe from "../../components/web/BussinesEpiqe"

const $ = window.$

export default class Vivepiqe extends Component {
    constructor(props){
        super(props);
        const $ = window.$

        this.state = {
            height  :   $(window).height(),
            width   :   $(window).width()
        }
    }

    updateDimensions = () =>{
        const $ = window.$

        this.setState({
            height  :   $(window).height(),
            width   :   $(window).width()
        })
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.updateDimensions);
        document.title = "Épiqe - "+this.props.title
    }

    componentWillMount = () =>{
        window.addEventListener("resize", this.updateDimensions);
    }

    render() {
        const $ = window.$

        function itemScroll(obj_act_top,item){
            if(obj_act_top > obj_top){
                var left = parseFloat($(item).css('left')) + 4;
            }else{
                var left = parseFloat($(item).css('left')) - 4;
            }
            $(item).css('left',left);
        }

        var obj = $(document);
        var obj_top = obj.scrollTop();
        obj.scroll(function(){
            var obj_act_top = $(this).scrollTop();
            itemScroll(obj_act_top, '#wt-01')
            itemScroll(obj_act_top, '#wt-02')
            itemScroll(obj_act_top, '#wt-03')
            itemScroll(obj_act_top, '#wt-04')
            itemScroll(obj_act_top, '#wt-05')
            itemScroll(obj_act_top, '#st-01')
            itemScroll(obj_act_top, '#st-02')
            itemScroll(obj_act_top, '#st-03')
            itemScroll(obj_act_top, '#st-04')
            itemScroll(obj_act_top, '#aypt-01')
            itemScroll(obj_act_top, '#aypt-02')
            itemScroll(obj_act_top, '#aypt-03')
            itemScroll(obj_act_top, '#aypt-04')
            obj_top = obj_act_top;
        });
        return (
        <div className="content-vivepiqe2">
            <Controller globalSceneOptions={{triggerElement:"#inicio", triggerHook: 'onLeave' }}>
            <Scene duration={parseFloat(this.state.height) / 1}>
            {(progress) => (
                <section className="wide-section" id="inicio" style={{height:this.state.height,backgroundImage: `url(${ResponsiveVideo1})`}}>
                <div className="section-container">
                    <div id="welcome-bg" className="section-background">
                        <div className="section-background-container">
                            <div className="section-video">
                                <MediaQuery query="(min-width: 1080px)">
                                <video loop={true} autoPlay={true} muted={true}>
                                    <source src={Video1} type="video/mp4"></source>
                                </video>
                                </MediaQuery>
                                <div className="sm-fallback">
                                    <div className="image s1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-floating-things">
                        <div className="section-floating-things-container">
                            <div id="wt-01" className="floating-thing">
                                <img inline="true" src={Bubles1} />
                            </div>
                            <div id="wt-02" className="floating-thing">
                                <img inline="true" src={Bubles2} />
                            </div>
                            <div id="wt-03" className="floating-thing">
                                <img inline="true" src={Bubles3} />
                            </div>
                            <div id="wt-04" className="floating-thing">
                                <img inline="true" src={Bubles4} />
                            </div>
                            <div id="wt-05" className="floating-thing">
                                <img inline="true" src={Bubles5} />
                            </div>
                        </div>
                    </div>
                <Tween           
                    to={{
                        top: '100%',
                        ease:"Linear.easeNone",
                    }}       
                    totalProgress={progress}
                    paused
                >
                    <div id="welcome-phrase" className="section-phrase">
                        <div className="section-phrase-container">
                            <div className="phrase">
                                Es momento <span className="whitespaced">de</span><br className="d" /><span className="whitespaced">lanzarse</span> al cambio...
                            </div>
                        </div>
                    </div>   
                </Tween>   
                    </div>
                </section> 
            )}
            </Scene>
            <Scene duration={parseFloat(this.state.height) / 0.5}>
            {(progress) => (
                <section className="wide-section" id="separator" style={{backgroundImage: `url(${sectionImage2})`, height:this.state.height}}>
                    <div id="una-vista-epica" className="link-hack"></div>
                    <div className="delimiter-wrap top">
                        <div className="delimiter" style={{backgroundImage: `url(${DelimiterTop})`,top:'-49px'}}></div>
                    </div>
                    <div className="section-container">
                        <div id="separator-bg" className="section-background">
                            <div className="section-background-container image-with-delimiters">
                                <div className="image landscape360"></div>
                            </div>
                        </div>
                        <div className="section-floating-things">
                            <div className="section-floating-things-container">
                                <div id="st-01" className="floating-thing nth-child-1">
                                    <img inline="true" src={Bubles1} />
                                </div>
                                <div id="st-02" className="floating-thing nth-child-4">
                                    <img inline="true" src={Bubles4} />
                                </div>
                                <div id="st-03" className="floating-thing nth-child-5">
                                    <img inline="true" src={Bubles5} />
                                </div>
                                <div id="st-04" className="floating-thing nth-child-2">
                                    <img inline="true" src={Bubles2} />
                                </div>
                            </div>
                        </div>
                        <Tween           
                            to={{
                                top: '100%',
                                ease:"Linear.easeNone",
                            }}       
                            totalProgress={progress}
                            paused
                        >
                            <div id="separator-phrase" className="section-phrase">
                                <div className="section-phrase-container">
                                    <div className="phrase">
                                        de iniciar con <span className="whitespaced">una</span><br className="d" /> vista épica
                                    </div>
                                </div>
                            </div>     
                    </Tween>
                    </div>
                    <div className="delimiter-wrap bottom">
                        <div className="delimiter" style={{backgroundImage: `url(${DelimiterBottom})`,bottom:'-49px'}}></div>
                    </div>
                </section> 
            )}
            </Scene>
            <Scene duration={parseFloat(this.state.height) / 0.318}>
            {(progress) => (
                <section className="wide-section" id="atyourpace" style={{height:this.state.height}}>
                    <div className="section-container">
                        <div id="welcome-bg" className="section-background">
                            <div className="section-background-container">
                                <div className="section-video">
                                    <video loop autoPlay={true} muted>
                                        <source src={Video2} type="video/mp4"></source>
                                    </video>
                                    <div className="sm-fallback">
                                        <div className="image s1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-floating-things">
                            <div className="section-floating-things-container">
                                <div id="aypt-01" className="floating-thing">
                                    <img inline="true" src={Bubles2} />
                                </div>
                                <div id="aypt-02" className="floating-thing">
                                    <img inline="true" src={Bubles3} />
                                </div>
                                <div id="aypt-03" className="floating-thing">
                                    <img inline="true" src={Bubles4} />
                                </div>
                                <div id="aypt-04" className="floating-thing">
                                    <img inline="true" src={Bubles5} />
                                </div>
                            </div>
                        </div>
                        <Tween           
                            to={{
                                top: '100%',
                                ease:"Linear.easeNone",
                            }}       
                            totalProgress={progress}
                            paused
                        >
                        <div id="ultimate-phrase" className="section-phrase">
                            <div className="section-phrase-container">
                                <div className="phrase">
                                    de vivir a tu ritmo
                                </div>
                            </div>
                        </div>        
                    </Tween>
                    </div>
                </section>
            )}
            </Scene>
            </Controller>
            <BussinesEpiqe content="vivepiqe"/>
        </div>
        )
    }
}
