import React, { Component } from 'react'
import Banner from '../../components/web/CarouselBanner'
import BussinesEpiqe from '../../components/web/BussinesEpiqe'
import Atributos from "../../components/web/Atributos"
import AreasSociales from "../../components/web/AreasSocialesSlick"
import Departamentos from "../../components/web/DepartamentosSlick"
import Exclusividad from "../../components/web/Exclusividad"
import {SliderService, IndicatorService} from "../../components/services"

export default class Inicio extends Component {
  
  constructor(props) {
    super(props);
    this.sliderService = new SliderService();
    this.indicatorService = new IndicatorService();
    this.indicators = [];
    this.state = {
      slides: [],
    };
    this.slides = [];
  }
  getSlidersData() {
    
    this.sliderService.get().then(({data}) => {
      const sliders = data
      const __getSlides = (title) => {
        return sliders.find(slider => slider.title == title).images
      }
      
      const __getSliderIndicators = (title) => {
        return __getSlides(title).map(({indicator}) => indicator)
      }
      
      this.setState(state => (
          state.slides = {
            promociones: __getSlides('Promociones'),
            sociales: __getSlides('Areas sociales'),
            departamentos: __getSlides('Departamentos'),
            ubicacion: __getSlides('Ubicacion'),
          }
      ))

      }).catch((error) => {
        console.log(error)
      });
      
    }
    
    componentWillMount = () =>{
      document.title = "Épiqe - "+this.props.title
      this.getSlidersData()
    }
    
    render() {
      return (
        
        <div className="content_inicio">
          <Banner slides={this.state.slides.promociones} />
          <BussinesEpiqe />
          <Atributos />
          <AreasSociales slides={this.state.slides.sociales} />
          <div id="departamentos"/>
          <Departamentos slides={this.state.slides.departamentos} />
          <Exclusividad slides={this.state.slides.ubicacion} />
        </div>
        )
      }
    }
    