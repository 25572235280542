import React, { Component } from 'react';

export default class NotFound extends Component {

    render() {
        return (
            <div>Not Found</div>
        );
    }
}
