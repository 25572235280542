import React, { Component, lazy, Suspense } from 'react'
import "./../../../assets/scss/pages/main.scss"
const Header = lazy(() => import("../../web/Header"))
const TermsCondition = lazy(() => import("../../web/TermsCondition"))

export default class PromoLayout extends Component {
  render() {
    const Component = this.props.component;
    const route = this.props.route;
    const user  = this.props.user;
    return (
      <div>
            <Suspense fallback={<div />}>
                <Header />
                <Component route={route}/>
                <TermsCondition />
            </Suspense>
      </div>
    )
  }
}
