import React, { Component } from 'react'

export default class ThankYou extends Component {
    // constructor(){
    //     document.querySelector('#contacto').style.display = "none";
    // }
    componentDidMount(){
        setTimeout(() => {
            document.querySelector('#contacto').style.display = "none"
        }, 1000);
    }
    render() {
        return (
            <div className="content_thankyou" style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <h1 class="generic-green-h1">¡Mensaje enviado!</h1>
                <h2 class="generic-purple-h2">Uno de nuestros asesores se pondrá en contacto contigo</h2>
            </div>
        )
    }
}
