import React, { Component } from 'react'
import {Link} from "react-router-dom";
import AdminLayout from "../../components/other/AdminLayout"

export default class Dashboard extends Component {
    render() {
      return (
        <AdminLayout title={this.props.title} subtitle={this.props.subtitle}>
            <div className="row">
                <div class="col-12 col-md-6">
                    <div class="card text-white bg-success mb-3">
                        <div class="card-body">
                            <h5 class="card-title">Slider</h5>
                            <p class="card-text">Agrega imagenes a los diferentes sliders de la página web.</p>
                            <div class="text-right">
                                <Link to="/admin/slider" class="btn btn-light">Administrar</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card text-white bg-warning mb-3">
                        <div class="card-body">
                            <h5 class="card-title">Indicadores</h5>
                            <p class="card-text">Agrega indicadores a las imágenes de los sliders.</p>
                            <div class="text-right">
                                <Link to="/admin/indicadores" class="btn btn-light">Administrar</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card text-white bg-dark mb-3">
                        <div class="card-body">
                            <h5 class="card-title">Leads</h5>
                            <p class="card-text">Visualiza los leads ingresados.</p>
                            <div class="text-right">
                                <Link to="/intranet/leads/proyectos" class="btn btn-light">Visualizar</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card text-white bg-primary mb-3">
                        <div class="card-body">
                            <h5 class="card-title">Promociones</h5>
                            <p class="card-text">Gestiona las promociones.</p>
                            <div class="text-right">
                                <Link to="/admin/promociones" class="btn btn-light">Administrar</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card text-white bg-info mb-3">
                        <div class="card-body">
                            <h5 class="card-title">Tipologias</h5>
                            <p class="card-text">Gestiona las tipologías que van de acuerdo a cada fase.</p>
                            <div class="text-right">
                                <Link to="/admin/tipologias" class="btn btn-light">Administrar</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card text-white bg-danger">
                        <div class="card-body">
                            <h5 class="card-title"> 
                                <i class="far fa-file-pdf"></i> Brochure (<small><Link to="https://hara.pe/pdf/Hara-brochure.pdf" class="text-white"> Hara-brochure.pdf</Link></small>)
                            </h5>
                            <p class="card-text">
                                <input type="file" />
                            </p>
                            <div class="text-right">
                                <button type="submit" class="btn btn-light">Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
      )
    }  
}
