import React, { Component } from 'react'
import Defipromo from "../../components/web/DefiPromo"

export default class DefiPromo extends Component {
    componentDidMount = () =>{
        sessionStorage.setItem('popup', 'none');
    }
    render() {
        return (
            <div>
                <Defipromo slug={this.props.slug}/>
            </div>
        )
    }
}
