import React, { Component } from 'react'
import './modalnotice.scss'

export default class Modal extends Component {
    constructor(props){
        super(props)
        this.state = {
            modal: false,
        };
    }
    componentDidMount(){

        let $dialog = document.querySelector("dialog")

        $dialog.querySelectorAll('[data-close="modal"]').forEach(element => {
            element.onclick = () => {
                $dialog.classList.remove("--success")
                this.setState(({modal: false}))
            }
        });
        
        $dialog.onclick = event => {
            if (event.target === $dialog) {
                $dialog.classList.remove("--success")
                this.setState(({modal: false}))
            }
        }

    }

    // getDerivedStateFromProps(nextProps, prevState) {
    //     return {
    //         modal: nextProps.open,
    //     }
    // }
    modal(event){
        let type = event.target.dataset.type;
        type == "modal" ? this.setState({modal:false}) : type = "";
    }
    render() {
        return (
        <dialog className={this.props.open  ? "content-Modal --success" : "content-Modal --fail"} id="modal">
            <div className="all-modal">
                <div className="close" id="#cancel" data-close="modal"></div>
                <div className="loading"></div>
                <span className="text-center d-block text">Muchas gracias, un asesor te contactará pronto</span>
                {this.props.seller && this.props.seller.phone &&
                <React.Fragment>
                    <span className="paragraph">También,<span className="GothamRoundedBoldItalic"> puedes ponerte en contacto con</span></span>
                    <a href={`https://wa.me/51${this.props.seller.phone}?text=Hola, quiero más info acerca de Épiqe`} target="_blank" className="content-asesor">
                        <div className="content-img">
                            <img className='img' id="whatsapp" data-active="true" src={require('../../../assets/images/botton-whatsapp.png')}></img>
                        </div>
                        <div className="asesor">
                            <span className="name GothamRoundedBoldItalic">{this.props.seller && this.props.seller.name} <span className="adviser-name"></span></span>
                            <span className="attributes">Divertido, le gusta leer y el arroz con pollo</span>
                        </div>
                    </a>
                </React.Fragment>
                }
                <button className="btn m-auto btn-modal" data-close="modal">Aceptar</button>
            </div>
        </dialog>
        )
    }
}
