import React, { Component } from 'react'
import BackgroundImage from 'react-background-image-loader';

export default class ItemDepartamentos extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="content-image" onClick={this.props.evento} data-position={this.props.position} data-tipologia={this.props.tipologia} data-name={this.props.name}>
                <BackgroundImage className="myimage" data-url={this.props.image} src={this.props.image} placeholder=""/>
                <div className="text-description">
                    {this.props.legal ? '* ' + this.props.legal : ''}
                </div>
            </div>
        )
    }
}
