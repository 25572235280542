import React, { Component } from 'react'
import { Link } from "react-router-dom"
import AdminLayout from "../../components/other/AdminLayout"
import { PromotionService } from '../../components/services';


export default class Promotions extends Component {
    constructor(props){
        super(props);
        this.promotionService = new PromotionService();
        this.state = {
            promotions: []
        };
    }
    handleDelete(e, id) {
        if (window.confirm('¿Estas seguro? esta acción no se puede revertir.')){
            this.promotionService.delete(id).then(({data})=>{
                this.setState({
                    promotions: this.state.promotions.filter(promo => promo.id != id)
                })
                alert('Promoción eliminada')
            }).catch(({response})=>{
                console.log(response)
                alert('Error!')
            })
        }
    }
    componentDidMount(){
        this.promotionService.get().then(({data}) => {
            this.setState({promotions: data})
        }).catch(({response}) => {
            console.log(response)
        });
    }
    render() {
      return (
          <AdminLayout title={this.props.title} subtitle={this.props.subtitle} consideracion>
            <div className="row">
                <div className="col-md-12 col-sm-12 pb-5">
                    <button className="btn btn-primary btn-sm pull-right" style={{marginRight:'10px'}}><i className="fa fa-save"></i> Ajustar prioridad</button>
                    <Link to={"/admin/promociones/nuevo"} id="link_news_slider" className="btn btn-success btn-sm pull-right"><i className="fa fa-plus"></i> Crear promociones <span className="text-meses"></span></Link>
                    <hr></hr>
                    <div className="content_tab content_tab_promociones">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="promociones-tab" data-toggle="tab" href="#promociones" role="tab" aria-controls="promociones" data-msg="promociones" data-option="promociones" aria-selected="true">Promociones</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent2">
                            <div className="tab-pane fade show active p-2" id="promociones" role="tabpanel" aria-labelledby="promociones-tab">
                                <p><b>NOTA:</b><br /><small> Mover los items para ajustar el ordenamiento del item.</small></p>
                                <div className="row sortable_card">
                                    {
                                        this.state.promotions.map((promo, i)=>
                                            <div className="col-md-4 pb-2 container-card">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <small><b>Posición <span className="n_posicion">{++i}</span></b></small>
                                                    </div>
                                                    <img className="card-img-top" src={`/media/${promo.image}.jpg`} alt="Card image cap" />
                                                    <div className="card-body">
                                                        <h5 className="card-title">{promo.title}</h5>
                                                        <div className="btn-group" role="group" aria-label="Basic example">
                                                            <a href={`/media/${promo.image}.jpg`} className="btn btn-secondary btn-sm" target="_blank" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Abrir en nueva pestaña"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a>
                                                            <Link to={"/admin/promociones/imagen/1/editar"} className="btn btn-secondary btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Actualizar fotografía"><i className="fas fa-edit" aria-hidden="true"></i></Link>
                                                            <a href={`/media/${promo.image}.jpg`} className="btn btn-secondary btn-sm" target="_blank" download="" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Descargar"><i className="fa fa-download" aria-hidden="true"></i></a>
                                                            <a onClick={(e) =>this.handleDelete(e, promo.id)} className="btn btn-danger btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Eliminar"><i className="fas fa-trash" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </AdminLayout>
      )
    }
}
  
