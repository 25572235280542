import axios from 'axios';
import {LEADS_ENDPOINT} from '../config/endpoints';

/**
 * @author Juan Lopez Tello
 * */
export class LeadService {
    constructor() {}
    get() {
        return axios.get(LEADS_ENDPOINT).then(response => response.data);
    }
    create(lead) {
        const promises = [
            axios.post(LEADS_ENDPOINT, lead),
            axios.post("https://hooks.zapier.com/hooks/catch/5020375/odtjtg0/", JSON.stringify(lead))
        ]
        return axios.all(promises)
    }
}