import React, { Component } from 'react'
import AdminConsideracion from "../../components/other/AdminConsideracion"
import { SliderService, IndicatorService } from '../../components/services';
import {previewImages} from "../../components/admin/previewImages"
import NotFound from "../../components/common/NotFound"


// import update from 'react-addons-update';

export default class SliderUpdate extends Component {
    constructor(props){
        super(props)
        this.sliderService = new SliderService();
        this.indicatorService =  new IndicatorService();
        this.state = {
            view: false,
            image: [],
            payload: {},
            indicators: [],
            landscape_image: "",
            portrait_image: "",
            indicator: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImagePreview = (e) => {
            const file = e.target.files[0]
            switch (e.target.name) {
                case "landscape":
                    this.setState(({payload}) => payload.landscape_image = file)
                    break;
                case "portrait":
                    this.setState(({payload}) => payload.portrait_image = file)
                    break;
                case "indicator":
                    this.setState(({payload}) => payload.indicator = file)
                    break;
                default:
                    break;
            }
            console.log(this.state.payload);
            return previewImages(e);
        }

    }
    handleSubmit(e) {
        e.preventDefault();
        const payload = new FormData()
        Object.entries(this.state.payload).forEach(
            ([key, value]) => {
                if (value != null) {
                    payload.append(key, value)
                }
            }
        )

        let indicator = document.querySelector('select#indicator').value
        if (indicator != 'none') {
            payload.append('indicator_id', indicator)
        }

        console.log(...payload)

        this.sliderService.updateImage(this.props.imageId, payload)
        .then((result)=>{
            console.log(result)
            alert("Actualización exitosa")
        }).catch((error)=>{
            alert("Error")
            console.log(error.response)
        })
    }
    handleInput = (e) => {
        const target = e.target
        switch (target.name) {
            case "title":
                this.setState(({payload}) => payload.title = target.value)
                break;
            case "link":
                this.setState(({payload}) => payload.link = target.value)
                break;
            case "legal_disclaimer":
                this.setState(({payload}) => payload.legal_disclaimer = target.value)
                break;
            default:
                break;
        }
    }

    componentWillMount = () =>{
        this.sliderService.getImage(this.props.imageId)
        .then((image)=>{
            this.setState(state => (
                state.payload = {
                    title: image.title,
                    link: image.link,
                    legal_disclaimer: image.legal_disclaimer,
                }
            ))
            this.setState({
                view: true,
                image: image,
                landscape_image:`/media/${image.landscape_image}.jpg`,
                portrait_image:`/media/${image.portrait_image}.jpg`,
            })
        }).catch((error)=>{
            console.log(error.response)
            this.setState({view:false})
        })

        this.indicatorService.get()
        .then((indicators)=>{
            this.setState({indicators: indicators.data})
        }).catch((error)=>{
            console.error(error.response)
        });
    }
  render() {
        return (
            <div>
                <div className="container">
                    <div className="jumbotron dashboard">
                        <h1 className="display-4">{this.props.title}</h1>
                        <p className="lead">{this.props.subtitle}</p>
                        <hr className="my-4"></hr>
                        <div className="row">
                            <form onSubmit={this.handleSubmit}>
                                <div className="col-md-12 col-sm-12 pb-5">
                                    <AdminConsideracion />
                                    <button type="submit" className="btn btn-primary btn-sm pull-right" style={{marginRight:'10px'}}><i className="fa fa-save"></i> Actualizar</button>
                                    <button type="button" onClick={() => window.history.back()} className="btn btn-danger btn-sm pull-right"><i className="fa fa-ban"></i> Cancelar <span className="text-meses"></span></button>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="" className="control-label">Título</label>
                                                <input type="text" name="title" className="form-control input-sm" placeholder="Ingrese título" value={this.state.payload.title}  onChange={ this.handleInput }/>
                                                <small className="form-text text-muted">Campo opcional.</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="" className="control-label">Link</label>
                                                <input type="text" name="link" className="form-control input-sm" placeholder="Ej: http://pagina.com, #seccion" value={this.state.payload.link} onChange={ this.handleInput } />
                                                <small className="form-text text-muted">Campo opcional.</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="" className="control-label">Nota legal</label>
                                                <textarea rows="2" name="legal_disclaimer" className="form-control input-sm" placeholder="Ingrese nota legal." value={this.state.payload.legal_disclaimer} onChange={ this.handleInput } ></textarea>
                                                <small className="form-text text-muted">Campo opcional.</small>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <p>Categoría</p>
                                            <select name="" id="indicator" className="form-control">
                                                <option value="none">(seleccionar)</option>
                                                {this.state.indicators.map((indicator, i) =>  
                                                <option value={indicator.id}>{indicator.title}</option>
                                                )}
                                            </select>
                                            <small className="form-text text-muted">Campo opcional.</small>
                                        </div> 
                                        <div className="col-md-8">
                                            <p>Imagen para escritorio</p>
                                            <label htmlFor="selected-image_desktop" className="btn btn-primary"><span className="fa fa-cloud-upload-alt"></span>&nbsp;&nbsp;Añadir fotografía</label>
                                            <input onChange={(e)=> this.setState({landscape_image: this.handleImagePreview(e)}) } id="selected-image_desktop" className="custom-file-input input-hidden" type="file" lang="es" name="landscape" />
                                            <img alt="" src={this.state.landscape_image} id="img_reader_slider" className="img img-fluid img-thumbnail" />
                                        </div>
                                        <div className="col-md-4">
                                            <p>Imagen para móvil</p>
                                            <label htmlFor="selected-image_movil" className="btn btn-primary"><span className="fa fa-cloud-upload-alt"></span>&nbsp;&nbsp;Añadir fotografía</label>
                                            <input onChange={(e)=> this.setState({portrait_image: this.handleImagePreview(e)})} id="selected-image_movil" className="custom-file-input input-hidden" type="file" lang="es" name="portrait" />
                                            <img alt="" src={this.state.portrait_image} id="img_reader_slider" className="img img-fluid img-thumbnail" />
                                            <small className="form-text text-muted">Campo opcional.</small>
                                        </div> 
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )  
  }
}
