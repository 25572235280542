import React, { Component } from 'react'
import {PhotoSwipe} from 'react-photoswipe';
import Slider from 'react-slick';
import MagicSliderDots from 'react-magic-slider-dots';
import MediaQuery from 'react-responsive';
import { Controller, Scene } from 'react-scrollmagic';
//Dinamic component
import ItemAreasSociales from "./ItemAreasSociales"
import ArrowSlick from "./ArrowSlick"
import $ from "jquery"

export default class AreasSocialesSlick extends Component {
    constructor(props){
        super(props);
        this.state = ({
            data: [],
            items: [],
            options: {},
            asocial: 0,
            owlItems: [],
            indicatorItems: [],
            slideIndex: 0,
            updateCount: 0,
            isOpen: false,
        })
    }

    fnSetImages = () => {
        var array = [];
        var title = '';
        const $ = window.$

        $.each($(".myCarouselAreasSociales .slick-slide:not(.slick-slide.cloned) .content-image"), function( index, value ) {
            var img = new Image;
            img.src = $(value).find('.myimage').data('url');
            array.push({
                src:$(value).find('.myimage').data('url'),
                w:img.width,
                h:img.height,
            })
        });
        this.setState({
            items:array
        });
    }

    openPhotoSwipe = (e) => {
        e.preventDefault();
        this.fnSetImages();
        var position = parseInt(e.currentTarget.getAttribute('data-position')) - 1;
        this.setState({
          isOpen: true,
          options: {
            index:position,
            closeOnScroll: false
          }
        });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    componentDidUpdate = async (prevProps) =>{
        if (this.props.slides !== prevProps.slides) {

            const indicators = await this.props.slides.map(({indicator}) => indicator),
                  unique_indicators = [...new Set(this.props.slides.map(({indicator}) => indicator.id))]

            await unique_indicators.map((id, i) => {
                let indicator = indicators.find(ind => ind.id == id)
                this.setState(({indicatorItems}) => (indicatorItems.push(
                    <li>
                        <a href="javascript:;" onClick={(e) => this.fnSetButtons(id)} data-map-index={id} data-toggle="tooltip" data-placement="top" title={indicator.title} className={(i == 0? 'active': false)}>
                            <div className="circle-svg">
                                 <div className="indicator" style={{ WebkitMaskImage: `url(https://epiqe.pe/media/${indicator.image})` }}></div>
                            </div>
                        </a>
                    </li>
                ), indicatorItems))

            })

            await this.props.slides.map((slide, i) => {
                this.setState(({owlItems}) => (owlItems.push(
                    <ItemAreasSociales
                        key={++i}
                        position={i}
                        categoria={slide.indicator.id}
                        name={slide.title}
                        image={`https://epiqe.pe/media/${slide.landscape_image}.jpg`}
                        legal={slide.legal_disclaimer}
                        evento={this.openPhotoSwipe}
                    />
                ), owlItems))
            })
        }
    }

    fnSetButtons = (index) => {
        console.log(index)
        var position = $('.myCarouselAreasSociales .slick-slide .content-image[data-categoria='+index+']').data('position')
        this.slider.slickGoTo(parseInt(position)-1)
    }

    render() {
    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable:true,
        swipe:true,
        swipeToSlide:true,
        cssEase: 'linear',
        nextArrow: <ArrowSlick/>,
        prevArrow: <ArrowSlick/>,
        appendDots: (dots) => {
            return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={20} />
        },
        afterChange: (event) => {
            const $ = window.$
            let name = $('.myCarouselAreasSociales .slick-slide:nth('+event+') .content-image').data('name');
            let position = $('.myCarouselAreasSociales .slick-slide:nth('+event+') .content-image').data('position');
            let total = $('.myCarouselAreasSociales .slick-slide:not(.slick-slide.cloned)').length;
            let categoria = $('.myCarouselAreasSociales .slick-slide:nth('+event+') .content-image').data('categoria');
            $('.name_areas_sociales').text(name);
            $('.position_areas_sociales').text(position+'/'+total);
            if($('.side_links_wrap2 ul li a').removeClass('active')){
                $('.side_links_wrap2 ul li a[data-map-index='+categoria+']').addClass('active')
            }
        }
    };
    return (
        <Controller>
            <Scene classToggle={[".content-slider-areasociales","moveToUp2"]} triggerElement=".trigger_areasociales" reverse={true} indicators={false}>
            <div className="page_front content-areas-sociales margin-page-special">
                <MediaQuery query="(min-width: 550px)">
                    <div className="trigger trigger_areasociales"></div>
                </MediaQuery>
                <h1 className="generic-green-h1">Experimenta momentos<br/> únicos e inolvidables</h1>
                <h2 className="generic-purple-h2">Nuestras áreas sociales</h2>
                <br/>
                <div className="side_links_wrap side_links_wrap2">
                    <ul>
                        {this.state.indicatorItems}
                    </ul>
                </div>
                <br />
                <div className="content-slider content-slider-areasociales">
                    <Slider ref={slider => (this.slider = slider)} {...settings} className="myCarouselAreasSociales owl-nav">
                        { this.state.owlItems }
                    </Slider>
                    <div className="contentTextName">
                        <div className="contentBackgroundGreen" style={{backgroundImage:`url(${require("./../../assets/images/nameBackgroundGreen.svg")})`}}/>
                        <div className="contentBackgroundPurple" style={{backgroundImage:`url(${require("./../../assets/images/nameBackgroundPurple.svg")})`}}/>
                        <div className="text-infSlider name_areas_sociales">Zona grill</div>
                    </div>
                </div>
                <PhotoSwipe id="photoswipe-areas-sociales" isOpen={this.state.isOpen} items={this.state.items} options={this.state.options} onClose={this.handleClose}/>
            </div>
            </Scene>
        </Controller>
    )
  }
}
