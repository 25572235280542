import React, { Component } from 'react'
import { Controller, Scene } from 'react-scrollmagic';
import {PhotoSwipe} from 'react-photoswipe';
import Ft_1 from "./../../assets/svg/ft-1.svg"
import Ft_2 from "./../../assets/svg/ft-2.svg"
import ItemEligeDepa from "./ItemEligeDepa"

export default class EligeDepa extends Component {
    constructor(props){
        super(props);
        this.state = ({
            isOpen:false,
            items:[],
            options:{}
        })
    }

    fnSetImages = () => {
        var array = [];
        var title = '';
        const $ = window.$

        $.each($(".content-tipologias .item-tipologia"), function( index, value ) {
            title = $(value).find('h4').text();
            var img = new Image;
            img.src = $(value).find('.mascara').data('url');
            var bgImgWidth = img.width;
            var bgImgHeight = img.height;
            array.push({
                src:$(value).find('.mascara').data('url'),
                w:img.width,
                h:img.height,
                title:title
            })
        });
        this.setState({
            items:array
        });
    }

    openPhotoSwipe = (e) => {
        e.preventDefault();
        this.fnSetImages();
        var position = parseInt(e.currentTarget.getAttribute('data-position')) - 1;
        this.setState({
          isOpen: true,
          options: {
            index:position,
            closeOnScroll: false
          }
        });
    };

    handleClose = () => {
        this.setState({
          isOpen: false
        });
    };

    render() {
        return (
            <div className="content-section-eligeDepa page_front">
                <h1 className="generic-green-h1">Elige el depa</h1>
                <h2 className="generic-purple-h2">A tu medida</h2>
                <br />
                <div className="content-tipologias">

                    <div className="section-floating-things areas-exclusivas" style={{pointerEvents:'none',zIndex:'0',height:'100%'}}>
                        
                        <Controller>
                            <Scene duration={800} >
                                {(progress) => (
                                    <div className="section-floating-things-container">
                                        <div id="aet-01" className="floating-thing" style={{left:'calc(325.25px + '+(progress * 30)+'%)',top:'50px'}}>
                                            <div className="ft-item-svg" style={{backgroundImage: `url(${Ft_1})`}} />
                                        </div>
                                        <div id="aet-02" className="floating-thing" style={{left:'calc(-313.266px + '+(progress * 30)+'%)',top:'336px'}}>
                                            <div className="ft-item-svg" style={{backgroundImage: `url(${Ft_2})`}} />
                                        </div>
                                        <div id="aet-03" className="floating-thing" style={{left:'calc(1084.89px + '+(progress * 30)+'%)',top:'212px'}}>
                                            <div className="ft-item-svg" style={{backgroundImage: `url(${Ft_1})`}} />
                                        </div>
                                        <div id="aet-04" className="floating-thing" style={{left:'calc(800.86px + '+(progress * 30)+'%)',top:'517px'}}>
                                            <div className="ft-item-svg" style={{backgroundImage: `url(${Ft_2})`}} />
                                        </div>
                                        <div id="aet-05" className="floating-thing" style={{left:'calc(-200.141px + '+(progress * 30)+'%)',top:'600px'}}>
                                            <div className="ft-item-svg" style={{backgroundImage: `url(${Ft_1})`}} />
                                        </div>
                                        <div id="aet-06" className="floating-thing" style={{left:'calc(420.8px + '+(progress * 30)+'%)',top:'785px'}}>
                                            <div className="ft-item-svg" style={{backgroundImage: `url(${Ft_1})`}} />
                                        </div>
                                    </div>
                                )}
                            </Scene>
                        </Controller>
                        
                    </div>

                    <div className="row" style={{zIndex:'2'}}>
                    {this.props.tipologias.map(fill =>
                        <ItemEligeDepa key={fill.position} name={fill.name} image={fill.image} metraje={fill.metraje} dormitorio={fill.dormitorio} position={fill.position} evento={this.openPhotoSwipe}/>
                    )}  
                    </div>
                </div>
                <br/>
                <PhotoSwipe isOpen={this.state.isOpen} items={this.state.items}
                options={this.state.options}
                onClose={this.handleClose}/>
            </div>
        )
    }
}
