import React, { Component } from 'react';
import {LeadService} from "../services";
import SimpleReactValidator from "simple-react-validator"
import {gtm} from "../google/gtag";
import $ from "jquery"
import Axios from 'axios';
import Modal from './Landing/Modal';

export default class FormFooter extends Component {
    constructor(props) {
        super(props);
        this.handleCreate = this.handleCreate.bind(this);
        this.openTerms = this.openTerms.bind(this)
        this.leadService = new LeadService();
        this.state = {
            startValid: false,
            names: '',
            last_name: '',
            phone: '',
            email: '',
            myselect: '',
            motive: '',
            time_range: '',
            contact_date: '',
            medium: '',
            accepted: true,
            modal: {}
        };
        setTimeout(() => {
            // document.querySelector("dialog").classList.add("--success")
            // this.setState({modal: true})

        }, 3000);
        this.validator = new SimpleReactValidator();
    }

    fnClearInputs = () => {
        this.setState({
            names: '',
            last_name: '',
            phone: '',
            email: '',
            myselect: '',
            motive: '',
            contact_date: '',
            medium: '',
            time_range: '',
            accepted: false,
        })
    }

    fnMensaje = (mensaje, option) => {
        $('.alert-epiqe-footer').text(mensaje).addClass(option).fadeIn('slow')
        $('.button-submit').removeAttr('disabled').removeClass('disabled');
        setTimeout(function () {
            $('.alert-epiqe-footer').removeClass(option).fadeOut('slow')
        }, 1500)
    }

    submitForm = () => {
        if (this.validator.allValid()) {
            this.handleCreate();
        } else {
            this.setState({
                startValid: true
            })
        }
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
        console.log(this.state)
    }

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);
    }

    handleCreate(event) {
        $('.button-submit').attr('disabled', 'true').addClass('disabled');
        var thes = this;

        this.leadService.create({
            fname: this.state.names,
            lname: this.state.last_name,
            telephone: this.state.phone,
            email: this.state.email,
            apartment_type: this.state.myselect,
            observation: `Interesado en ${this.state.myselect} domitorios; Motivo ${this.state.motive}; Contactar en la fecha ${this.state.contact_date} ${this.state.time_range} usando ${this.state.medium}`,
            url_query: window.location.search
        }).then(Axios.spread((...responses) => {
            gtm.gtag('event', 'lead', {
                value: 1,
                event_label: 'lead:submit',
                event_category: 'ecommerce'
            })
            
            gtm.gtag('event', 'conversion', {
                'send_to': 'AW-794707343/HDMCCLnI3dsBEI-L-foC'
            });

            const lead = responses[0].data
            if(lead.seller_phone){
                const seller_name = lead.seller_name.split(" ")
                this.setState({modal: {
                    open: true,
                    seller: {name: seller_name[seller_name.length -1], phone: lead.seller_phone || null},
                }})
            }
            
            console.log(gtm.getDataLayer())
            console.log(responses);
            setTimeout(() => {
                // window.location.href = "/gracias"
            }, 2000);
            // thes.fnMensaje("Registro exitoso","success");
            thes.fnClearInputs();
        })).catch(({
            response
        }) => {
            console.log(response);
            thes.fnMensaje("No se ha podido enviar tu solicitud", "error");
        })
    }
    

    openTerms = (e) => {
        e.preventDefault()
        $('#privacy-terms').addClass('modal display show')
    }

    render() {
        return (
            <div className={"content-form "+this.props.background}>
            <Modal {...this.state.modal}></Modal>
                {this.props.text[0]} <span className="text-epiqe">{this.props.text[1]}</span>
                <span className="text-small">{this.props.text[2]}</span>
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" autoComplete="off" className={"form-control text-center "+(this.validator.fieldValid('names') ? "" : ((this.state.startValid) ? "invalid": ""))} name="names" value={this.state.names} ref="fname" placeholder="Nombres" onChange={this.setStateFromInput}/>
                                {this.validator.message('names', this.state.names, 'required')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" autoComplete="off" className={"form-control text-center "+(this.validator.fieldValid('last_name') ? "" : ((this.state.startValid) ? "invalid": ""))} name="last_name" value={this.state.last_name} onChange={this.setStateFromInput} ref="lname" placeholder="Apellidos"/>
                                {this.validator.message('last_name', this.state.last_name, 'required')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="tel" autoComplete="off" className={"form-control text-center "+(this.validator.fieldValid('phone') ? "" : ((this.state.startValid) ? "invalid": ""))} name="phone" value={this.state.phone} onChange={this.setStateFromInput} ref="telephone" placeholder="Teléfono celular"/>
                                {this.validator.message('phone', this.state.phone, 'required|numeric|min:6|max:15')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="email" autoComplete="off" className={"form-control text-center "+(this.validator.fieldValid('email') ? "" : ((this.state.startValid) ? "invalid": ""))} name="email" value={this.state.email} onChange={this.setStateFromInput} ref="email" placeholder="Email"/>
                            </div>
                            {this.validator.message('email', this.state.email, 'required|email')}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <select className={"form-control text-center "+(this.state.myselect !== "" ? "" : ((this.state.startValid) ? "invalid": ""))} name="myselect" ref="apartment_type" value={this.state.myselect} onChange={this.setStateFromInput}>
                                    <option value="" disabled className="text-center">N° de dormitorios</option>
                                    <option value="1" className="text-center">1 dormitorio</option>
                                    <option value="2" className="text-center">2 dormitorios</option>
                                    <option value="3" className="text-center">3 dormitorios</option>
                                </select>
                                {this.validator.message('myselect', this.state.myselect, 'required')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <select className={"form-control text-center "+(this.state.motive !== "" ? "" : ((this.state.startValid) ? "invalid": ""))} name="motive" ref="motive" value={this.state.motive} onChange={this.setStateFromInput}>
                                    <option value="" selected disabled className="text-center">Motivo</option>
                                    <option value="Primera Vivienda" className="text-center">Primera Vivienda</option>
                                    <option value="Inversión" className="text-center">Inversión</option>
                                </select>
                                {this.validator.message('motive', this.state.motive, 'required')}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <input type="text" name="contact_date" className={"form-control text-center "+(this.validator.fieldValid('contact_date') ? "" : ((this.state.startValid) ? "invalid": ""))}
                                placeholder="¿En qué fecha prefiere que lo contactemos?"
                                value={this.state.contact_date}
                                onClick={({target})=>{target.type = "date"; target.click()}}
                                onFocus={({target})=>{target.type = "date";}}
                                onChange={this.setStateFromInput}
                                min={new Date().toISOString().split("T")[0]} />
                                {this.validator.message('contact_date', this.state.contact_date, 'required')}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <select className={"form-control text-center "+(this.state.time_range !== "" ? "" : ((this.state.startValid) ? "invalid": ""))} name="time_range" ref="time_range" value={this.state.time_range} onChange={this.setStateFromInput}>
                                    <option value="" selected disabled className="text-center">¿En qué horario lo contactamos?</option>
                                    <option value="entre las 9:00 a.m. y 12:00 p.m." className="text-center">Entre las 9:00 a.m. y 12:00 p.m.</option>
                                    <option value="entre las 12:00 p.m. y 3:00 p.m." className="text-center">Entre las 12:00 p.m. y 3:00 p.m.</option>
                                    <option value="entre las 3:00 p.m. y 6:00 p.m." className="text-center">Entre las 3:00 p.m. y 6:00 p.m.</option>
                                </select>
                                {this.validator.message('time_range', this.state.time_range, 'required')}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <select className={"form-control text-center "+(this.state.medium !== "" ? "" : ((this.state.startValid) ? "invalid": ""))} name="medium" ref="medium" value={this.state.medium} onChange={this.setStateFromInput}>
                                    <option value="" selected disabled className="text-center">¿Qué medio prefiere para contactarse?</option>
                                    <option value="Llamada" className="text-center">Llamada</option>
                                    <option value="WhatsApp" className="text-center">WhatsApp</option>
                                    <option value="Zoom" className="text-center">Zoom</option>
                                    <option value="Skype" className="text-center">Skype</option>
                                </select>
                                {this.validator.message('medium', this.state.medium, 'required')}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="form-check">
                                    <input type="checkbox" name="accepted" className="form-check-input element_hidden" checked={this.state.accepted} onChange={this.setStateFromCheckbox} id="exampleRadios1"/>
                                    {this.validator.message('checkbox', this.state.accepted, 'accepted')}
                                    <label className={"form-check-label text-white "+(this.state.accepted ? "" : ((this.state.startValid) ? "invalid": ""))} htmlFor="exampleRadios1">
                                        <div className="check-radius"><i className="fas fa-check"></i></div>&nbsp; Acepto las&nbsp;<a href="#" onClick={(e) => this.openTerms(e)} target="_blank" rel="noopener noreferrer">politicas de privacidad</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <button type="button"  className="button-submit button text-white" onClick={this.submitForm}>Enviar</button>
                            <div className="alert alert-epiqe-footer">No se ha podido enviar tu solicitud</div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
