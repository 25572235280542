import React, { Component } from 'react'
import ParticulasStar from "./ParticulasStar"
import BackgroundImage from 'react-background-image-loader';
import bgImage from "./../../assets/images/stars.png"
import bgEdificio from "./../../assets/images/edificio-epiqe.png"
import SvgViveEpiqe from "./../../assets/images/iconos/vive-en-epiqe-render.png"
import SvgDescription from "./../../assets/images/iconos/info_apartament.png"
import SvgQuieresSaberMas from "./../../assets/images/iconos/lets-data.png"
import SvgQuieresSaberMas2 from "./../../assets/images/iconos/lets-data.png"
import $ from 'jquery'

export default class BussinesEpiqe extends Component {
  FormDirect = () => {
      $("html, body").animate({ scrollTop: $(document).height() }, 600);
  }
  render() {
    return (
      <BackgroundImage className={"content-bussinesEpiqe "+this.props.content} src={bgImage} placeholder="">
        <ParticulasStar />
        <div className="container-bussines">
          <div className="content-image-bussines">
            <div className="content-left">
              <div className="img_bussines" style={{backgroundImage: `url(${bgEdificio})`}}></div>
            </div>
            <div className="content-right">
              <div className="img_vivepiqe" style={{backgroundImage: `url(${SvgViveEpiqe})`}}></div>
              <div className="img_description" style={{backgroundImage: `url(${SvgDescription})`}}></div>
              <div className="img_dejanostusdatos" style={{backgroundImage: `url(${SvgQuieresSaberMas})`}} onClick={() => this.FormDirect()}></div>
              <div className="img_dejanostusdatos" style={{backgroundImage: `url(${SvgQuieresSaberMas2})`}} onClick={() => this.FormDirect()}></div>
            </div>
          </div>
        </div>
        <div className="element_bottom">
            <div className="content_element_bottom">
              {/*<div className="suelo" style={{"-webkit-mask-image":`url(${bgInferior})`}}></div>*/}
              <div className="suelo"></div>
              <div className="superficie-inferior2"></div>
            </div>
        </div>
      </BackgroundImage>
    )
  }
}
