import React, { Component } from 'react'

export default class AdminFooter extends Component {
  render() {
    return (
        <div className="container">
            <hr />
            <footer>
                &copy; Fomula Perú - 2018
            </footer>
        </div>
    )
  }
}
