import React, { Component } from 'react'
import {Link} from "react-router-dom"
import { ProjectStageService, ProjectModelService } from '../../components/services';
import AdminLayout from "../../components/other/AdminLayout"


export default class Tipologias extends Component {
    constructor(props){
        super(props);
        this.projectStageService = new ProjectStageService();
        //this.projectModelService = new ProjectModelService();
        this.state = {
            stages: [],
            models: []
        };
    }
    componentDidMount(){
        this.projectStageService.get().then(({data}) => {
            this.setState({stages: data})
        }).catch(({response}) => {
            console.log(response)
        });

        /*this.projectModelService.get().then(({data}) => {
            this.setState({models: data})
        }).catch(({response}) => {
            console.log(response)
        });*/
    }
  render() {
    return (
            <AdminLayout className="styles_admin content_admin_tipologias" title={this.props.title} subtitle={this.props.subtitle} consideracion>
                <div className="row">
                    <div className="col-md-12 col-sm-12 pb-5">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {this.state.stages.map((stage, i) => 
                                <li className="nav-item">
                                    <a className={"nav-link " + (i == 0 ? 'active show': '')} id={stage.title + "-tab"} data-toggle="tab" href={"#stage-" + stage.id} role="tab" aria-controls={"#stage-" + stage.id} aria-selected="false">{stage.title}</a>
                                </li>
                            )}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            
                            {this.state.stages.map((stage, i) => 
                                <div className={"tab-pane fade " + (i == 0? "show active" : "") } id={"stage-" + stage.id} role="tabpanel" aria-labelledby="fase1-tab">
                                    <div className="row row_button">
                                    <Link to={"/admin/fase/" + stage.id + "/tipologias"} className="btn btn-primary btn-sm pull-right"><i className="fa fa-plus"></i> Agregar imagenes</Link>
                                        <button className="btn btn-sm btn-primary" style={{marginLeft:'10px'}}><i className="fa fa-save"></i> Actualizar prioridad</button>
                                    </div>

                                    <div className="row sortable_card1">
                                    {stage.models.map((model, i) => 
                                        <div className="col-md-4 pb-2 container-card">
                                            <div className="card">
                                                <div className="card-header">
                                                    <b>Posición {++i}</b>
                                                </div>
                                                <img alt="myImage" className="card-img-top" src={`/media/${model.image}.jpg`} alt="Card image cap"></img>
                                                <div className="card-body">
                                                    <h5 className="card-title">{model.title}</h5>
                                                    <h6 className="card-subtitle mb-2 text-muted">1 dormitorio - {model.area} m <sup>2</sup></h6>
                                                    <div className="btn-group" role="group" aria-label="Basic example">
                                                        <a href={`/media/${model.image}.jpg`} className="btn btn-secondary btn-sm" target="_blank" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Abrir en nueva pestaña"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a>
                                                        <Link to="" className="btn btn-secondary btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Actualizar fotografía"><i className="fas fa-edit" aria-hidden="true"></i></Link>
                                                        <a href={`/media/${model.image}.jpg`} className="btn btn-secondary btn-sm" target="_blank" download="" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Descargar"><i className="fa fa-download" aria-hidden="true"></i></a>
                                                        <a href="#" className="btn btn-danger btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Eliminar"><i className="fas fa-trash" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            )}

                            <div className="tab-pane fade" id="fase2" role="tabpanel" aria-labelledby="fase2-tab">
                                <div className="row row_button">
                                    <a href="/admin/slider/1/nueva-imagen" id="link_news_slider" className="btn btn-primary btn-sm pull-right"><i className="fa fa-plus"></i> Agregar imagenes</a>
                                    <button className="btn btn-sm btn-primary" style={{marginLeft:'10px'}}><i className="fa fa-save"></i> Actualizar prioridad</button>
                                </div>
                                <div className="alert alert-danger" role="alert">
                                Sin resultados.
                                </div>
                            </div>
                            <div className="tab-pane fade" id="fase3" role="tabpanel" aria-labelledby="fase3-tab">
                                <div className="row row_button">
                                    <a href="/admin/slider/1/nueva-imagen" id="link_news_slider" className="btn btn-primary btn-sm pull-right"><i className="fa fa-plus"></i> Agregar imagenes</a>
                                    <button className="btn btn-sm btn-primary" style={{marginLeft:'10px'}}><i className="fa fa-save"></i> Actualizar prioridad</button>
                                </div>
                                <div className="alert alert-danger" role="alert">
                                Sin resultados.
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </AdminLayout>
    )
  }
}
