import React, { Component } from 'react';
import bgHeader from "./../../assets/images/departamentos/bgheader.png";
import fase1 from "./../../assets/svg/fase1.svg";
import fase2 from "./../../assets/svg/fase2.svg";
import fase3 from "./../../assets/svg/fase3.svg";
import CarouselDepartamentos from "../../components/web/DepartamentosSlick";
import RecorridoVirtual from "../../components/web/RecorridoVirtual";
import EligeDepa from "../../components/web/EligeDepa"
import SVGrecorrido from "./../../assets/svg/recorrido_virtual.svg"
import bgRecorrido1 from "./../../assets/images/departamentos/recorrido_virtual.png"
import bgRecorrido2 from "./../../assets/images/departamentos/recorrido_virtual2.png"

//Tipologias
import Image1 from "./../../assets/images/tipologia/tipologia-1.jpg"
import Image2 from "./../../assets/images/tipologia/tipologia-2.jpg"
import Image3 from "./../../assets/images/tipologia/tipologia-3.jpg"
import Image4 from "./../../assets/images/tipologia/tipologia-4.jpg"

import Image5 from "./../../assets/images/tipologia/tipologia-5.png"
import Image6 from "./../../assets/images/tipologia/tipologia-6.png"
import Image7 from "./../../assets/images/tipologia/tipologia-7.png"
import { SliderService } from '../../components/services';

const $ = window.$

export default class Departamentos extends Component {
    constructor(props){
        super(props);
        this.sliderService = new SliderService();
        this.state = ({
            view:false,
            slides: [],
            data:[]
        })
    }
    fnSetData = () => {
        let fase = false;
        var response = [
            {
                "id":1,
                "name":"fase 1",
                "slug":"fase-1",
                "header":fase1,
                "position":1,
                "recorridos":true,
                "tipologias":[
                    {"name":"Tipología X01","metraje":"40","dormitorio":"1 dormitorio","position":"1","image":Image1},
                    {"name":"Tipología X02","metraje":"56","dormitorio":"2 dormitorio","position":"2","image":Image2},
                    {"name":"Tipología X03","metraje":"78","dormitorio":"3 dormitorio","position":"3","image":Image3},
                    {"name":"Tipología X04","metraje":"58","dormitorio":"2 dormitorio","position":"4","image":Image4},
                ],
                "item_recorridos":[
                    {"title":"Recorrido virtual","subtitle":"tipología X03","url":"https://my.matterport.com/show/?m=VkMMe867D6i&play=1&lang=es","svg":SVGrecorrido,"background":bgRecorrido1},
                    {"title":"Recorrido virtual","subtitle":"tipología X04","url":"http://virtualexperience.pe/3d-model/recorrido-virtual-gerpal-inmobiliaria-epiqe-olguin-600/fullscreen/","svg":SVGrecorrido,"background":bgRecorrido2}
                ]
            },
            {   
                "id":2,
                "name":"fase 2",
                "slug":"fase-2",
                "header":fase2,
                "position":2,
                "recorridos":true,
                "tipologias":[
                    {"name":"Tipología X05","metraje":"61.8","dormitorio":"2 dormitorio","position":"1","image":Image5},
                    {"name":"Tipología X06","metraje":"72.06","dormitorio":"3 dormitorio","position":"2","image":Image6},
                    {"name":"Tipología X07","metraje":"59.5","dormitorio":"2 dormitorio","position":"3","image":Image7},
                ],
                "item_recorridos":[]
            },
            {   
                "id":3,
                "name":"fase 3",
                "slug":"fase-3",
                "header":fase3,
                "position":3,
                "recorridos":true,
                "tipologias":[
                    {"name":"Tipología X08","metraje":"79.80","dormitorio":"3 dormitorio","position":"1","image":require("../../assets/images/tipologia/X08.png")},
                    {"name":"Tipología X09","metraje":"63.60","dormitorio":"2 dormitorio","position":"2","image":require("../../assets/images/tipologia/X09.png")},
                    {"name":"Tipología X10","metraje":"60.15","dormitorio":"2 dormitorio","position":"3","image":require("../../assets/images/tipologia/X10.png")},
                ],
                "item_recorridos":[]
            },
        ]
        fase = response.find(myfase => myfase.slug === this.props.fase);
        if (!fase) {
            this.setState({view:false});
            document.title = `No encontrado`;
        }else{
            this.setState({view:true,data:fase});
            document.title = `Épiqe` + (fase.name ? ' - ' +fase.name : '');
        }
    }

    componentWillMount = () =>{
        this.fnSetData();
    }
    componentDidUpdate = (prevProps, prevState) =>{
        const $ = window.$
        if(this.props.fase !== prevProps.fase){
            this.fnSetData();
            $('.toggle-button').removeClass('button-open');
            $('.content-menu-mobile').removeClass('button-open')
        }
    }
    render() {
        if (this.state.view) {
            return (
                <div className="content-departamentos page_front">
                    <div className="banner" style={{backgroundImage: `url(${bgHeader})`}}>
                        <img src={this.state.data.header} alt="thisimage"/>
                    </div>
                    <EligeDepa tipologias={this.state.data.tipologias}/>
                    <CarouselDepartamentos slides={this.state.slides.departamentos}/>
                    {this.state.data.item_recorridos.map(fill =>
                        <RecorridoVirtual title={fill.title} subtitle={fill.subtitle} url={fill.url} svg={fill.svg} image={fill.background}/>
                    )}  
                </div>
            )
        }else{
            return (<div>No encontrado</div>)
        }
    }
}
