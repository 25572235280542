import axios from 'axios'
import {
    INDICATORS_ENDPOINT
} from '../config/endpoints'

/**
 * @author Juan Lopez Tello
 * */
export class IndicatorService {
    constructor() {
        this.headers = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en,es;q=0.8',
                'Content-Type': `multipart/form-data;`,
              }
        }
    }
    get(indicator_id = false) {
        const endpoint = indicator_id ? `${INDICATORS_ENDPOINT}${indicator_id}` : INDICATORS_ENDPOINT;
        return axios.get(endpoint).then(response => response.data);
    }
    create(indicator) {
        return axios.post(INDICATORS_ENDPOINT, indicator);
    }
    update(indicator) {
        return axios.put(`${INDICATORS_ENDPOINT}${indicator.id}`, indicator);
    }
}