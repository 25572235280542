import {
    frontInicio,
    frontDepartamentos,
    frontAreasSociales,
    frontVivepiqe,
    frontPromociones,
    frontRespaldo,
    workProgress,
    frontThankYou,
    complaintsBook
} from '../components/WebComponents';
export default {
    frontInicio: {
        component: frontInicio,
        path: '/'
    },
    frontDepartamentos: {
        component: frontDepartamentos,
        path: '/departamentos/:fase'
    },
    frontAreasSociales: {
        component: frontAreasSociales,
        path: '/areas-sociales'
    },
    frontVivepiqe: {
        component: frontVivepiqe,
        path: '/viveepiqe'
    },
    frontLanding: {
        component: frontVivepiqe,
        path: '/landing'
    },
    frontPromociones: {
        component: frontPromociones,
        path: '/promociones'
    },
    workProgress: {
        component: workProgress,
        path: '/avance-obra'
    },
    frontRespaldo: {
        component: frontRespaldo,
        path: '/respaldo'
    },
    frontThankYou: {
        component: frontThankYou,
        path: '/gracias'
    },
    frontThankYou: {
        component: complaintsBook,
        path: '/libro-de-reclamaciones'
    },
};