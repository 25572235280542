import React, { Component } from 'react';
import "./../../../assets/scss/admin/main.scss";
import AdminHeader from "../../../components/admin/AdminHeader"
import AdminFooter from "../../../components/admin/AdminFooter"

export default class AdminLayout extends Component {
    render() {
        const Component = this.props.component;
        const route = this.props.route;
        const user = this.props.user;
        const userActions = this.props.userActions;
        return (
            <div>
                <AdminHeader username={user.name} userActions={userActions}/>
                <Component route={route}/>
                <AdminFooter />
            </div>
        );
    }
}