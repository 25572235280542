import axios from 'axios'
import {
    PROJECT_STAGE_ENDPOINT
} from '../config/endpoints'

/**
 * @author Juan Lopez Tello
 * */
export class ProjectStageService {
    constructor() {
        this.headers = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en,es;q=0.8',
                'Content-Type': `multipart/form-data;`,
              }
        }
    }
    get(id = false) {
        const endpoint = id ? `${PROJECT_STAGE_ENDPOINT}${id}` : PROJECT_STAGE_ENDPOINT;
        return axios.get(endpoint).then(response => response);
    }
    update(stage) {
        return axios.put(`${PROJECT_STAGE_ENDPOINT}${stage.id}`, stage);
    }
}