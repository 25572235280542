import React, { Component } from 'react'
import Carousel from "../web/Carousel"

export default class ItemObras extends Component {
    render() {
        return (
            <div className="tl-content-item">
                <div className={"tl-item tl-item-"+this.props.index}>
                    <span translate="no"className="tl-text-header"></span>
                    <Carousel id={this.props.index} title={this.props.title} images={this.props.images} photoswipe={this.props.photoswipe}/>
                </div>
            </div>    
        )
    }
}
