import React, { Component } from 'react'
import { Link } from "react-router-dom"
import BackgroundImage from 'react-background-image-loader';
import { PromotionService } from '../services';
import { promotions } from '../../data/promotions';


export default class ItemPromocion extends Component {
    constructor(props){
        super(props);
        this.promotionService = new PromotionService();
        this.state = {
            view: false,
            promotions: []
        }
    }
    componentWillMount = () =>{
        // this.promotionService.get()
        // .then((promotions)=>{
        //     this.setState({promotions : promotions.data})
        // }).catch((error)=>{
        //     console.error(error)
        // })
    }
    render() {
            return (
                <div className="promotion-gallery">
                    {promotions.map((promo, i) =>  
                        <div className="promo-container" key={i}>
                            <Link to={"/promociones/"+ promo.slug} rel="noopener noreferrer">
                                <div className="promotion">
                                    <BackgroundImage className="promo-image"  src={promo.image} placeholder=""/>
                                    <span className="promo-hover">
                                        <span>{promo.name}</span>
                                    </span>
                                </div>
                            </Link>
                        </div>
                    )}
                </div>
            )
    }
}
