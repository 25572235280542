import React, { Component } from 'react'
import ItemObras from "../../components/other/ItemObras"
import IMG1 from "./../../assets/images/avanceobra/1.jpg"
import IMG2 from "./../../assets/images/avanceobra/2.jpg"
import IMG3 from "./../../assets/images/avanceobra/3.jpg"
import IMG4 from "./../../assets/images/avanceobra/4.jpg"
import IMG5 from "./../../assets/images/avanceobra/march2020/01.jpg"
import IMG6 from "./../../assets/images/avanceobra/march2020/02.jpg"
import IMG7 from "./../../assets/images/avanceobra/march2020/03.jpg"
import IMG8 from "./../../assets/images/avanceobra/march2020/04.jpg"
import Covid from "./../../assets/images/avanceobra/covid-19.jpg"

export default class WorkProgress extends Component {
    constructor(props){
        super(props);
        this.state = {
            data : [],
            isOpen:false,
            items:[],
            options:{},
        }
    }
    componentDidMount = () =>{
        document.title = "Avance de obra";
        this.getData();
        // window.ga('send', 'pageview', window.location.pathname)
    }
    getData = () =>{
        var myData = [
            {
                'id': 0,
                'title':'Junio 2020',
                'images':[
                    {
                        'position':1,
                        'url':Covid
                    },
                ]
            },
            {
                'id': 1,
                'title':'Mayo 2020',
                'images':[
                    {
                        'position':1,
                        'url':Covid
                    },
                ]
            },
            {
                'id': 2,
                'title':'Abril 2020',
                'images':[
                    {
                        'position':1,
                        'url':Covid
                    },
                ]
            },
            {
                'id': 3,
                'title':'Marzo 2020',
                'images':[
                    {
                        'position':1,
                        'url':IMG5
                    },
                    {
                        'position':2,
                        'url':IMG6
                    },
                    {
                        'position':3,
                        'url':IMG7
                    },
                    {
                        'position':4,
                        'url':IMG8
                    },
                ]
            },
            {
                'id': 4,
                'title':'Febrero 2020',
                'images':[
                    {
                        'position':2,
                        'url':IMG3
                    },
                    {
                        'position':3,
                        'url':IMG4
                    },
                ]
            },
            {
                'id': 5,
                'title':'Enero 2020',
                'images':[
                    {
                        'position':0,
                        'url':IMG1
                    },
                    {
                        'position':1,
                        'url':IMG2
                    }
                ]
            },
        ];
        this.setState({ data: myData });
    }
    render() {
        return (
            <div className="content-workprogress margin-page-special">
                <h1 className="generic-green-h1">Avance de Obra</h1>
                <div className="content-tl">
                    <div className="vertical-tl"></div>
                    {
                        this.state.data.map((fill,index) => 
                            <ItemObras key={index} index={fill.id} title={fill.title} images={fill.images} photoswipe={this} />
                        )
                    }
                </div>
            </div>
        )
    }
}