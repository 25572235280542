import React, { Component } from 'react'
import AdminConsideracion from "../../components/other/AdminConsideracion"

export default class ImageManager extends Component {
    constructor(props){
        super(props);
    }
  render() {
        return(
            <div className="container">
            <div className="jumbotron dashboard">
                <h1 className="display-4">{this.props.title}</h1>
                <p className="lead">{this.props.desc}</p>
                <hr className="my-4"></hr>
                {this.props.children}
            </div>
        </div>
        )
  }
}
