import axios from 'axios'
import {
    PROMOTIONS_ENDPOINT
} from '../config/endpoints'

/**
 * @author Juan Lopez Tello
 * */
export class PromotionService {
    constructor() {
        this.headers = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en,es;q=0.8',
                'Content-Type': `multipart/form-data;`,
              }
        }
    }
    get(slug_or_id = false) {
        const endpoint = slug_or_id ? `${PROMOTIONS_ENDPOINT}${slug_or_id}` : PROMOTIONS_ENDPOINT;
        return axios.get(endpoint).then(response => response.data);
    }
    create(promotion) {
        return axios.post(PROMOTIONS_ENDPOINT, promotion);
    }
    update(promotion) {
        return axios.put(`${PROMOTIONS_ENDPOINT}${promotion.id}`, promotion);
    }
    delete(id) {
        return axios.delete(`${PROMOTIONS_ENDPOINT}${id}`);
    }
}