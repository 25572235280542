import React, { Component, lazy, Suspense } from 'react';
import "./../../../assets/scss/pages/main.scss"
import Footerfinal from '../../../components/web/footer/footer';
const StickyContact = lazy(() => import("../../web/StickyContact"))
const Header = lazy(() => import("../../web/Header"))
const Footer = lazy(() => import("../../web/Footer"))
const TermsCondition = lazy(() => import("../../web/TermsCondition"))
const Popup = lazy(() => import("../../web/Popup"))

export default class PublicLayout extends Component {
    render() {
        const Component = this.props.component;
        const route = this.props.route;
        return (
            <div>
                <Suspense fallback={<div />}>
                    <Header />
                    <StickyContact />
                    <Popup />
                    <Component route={route}/>
                    {/* <Footer background="bg-purple" text={["Llena el formulario para más","información"]}/> */}
                    <Footer background="bg-purple" text={["¡Bienvenido a Épiqe!","Estás a un paso de cumplir tus sueños.", "Completa el formulario para comunicarnos contigo"]}/>
                    <Footerfinal></Footerfinal>
                    <TermsCondition />
                </Suspense>
            </div>
        );
    }
}