import React, { Component } from 'react'
import AdminLayout from "../../components/other/AdminLayout"
import SimpleReactValidator from "simple-react-validator"

export default class PromotionUpdate extends Component {
  constructor(props){
    super(props);
    this.state = ({
      startValid: false,
      titulo:"Promo Joy n' fun 2 dormitorios",
      slug: 'promo-join-fun-2dorms',
      image:'https://epiqe.pe/images/promo-joynfun2.c9943381df83629666095e1d9e743881.png'
    })
    this.handleCreate = this.handleCreate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator();
  }

  handleCreate(event){
      alert('se ejecuta el codigo cuando este ya validado')
  }

  handleSubmit(event) {
      event.preventDefault();
      this.submitForm();
  }

  setStateFromInput = (event) =>{
      var obj = {};
      obj[event.target.name] = event.target.value;
      this.setState(obj);
  }

  submitForm = () =>{
      if( this.validator.allValid() ){
          this.handleCreate();
      } else {
          this.setState({ startValid: true }) 
      }
  }

  render() {
    return (
      <AdminLayout title={this.props.title} subtitle={this.props.subtitle} consideracion>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
              <div className="col-12 col-md-12 col-sm-12 pb-2">
                  <div className="row">
                      <div className="col-md-6">
                          <div className={"form-group "+(this.validator.fieldValid('titulo') ? "" : ((this.state.startValid) ? "is-invalid": ""))}>
                              <label for="" className="control-label">Título</label>
                              <input type="text" autoComplete="off" name="titulo" className="form-control input-sm" value={this.state.titulo} onChange={this.setStateFromInput} placeholder="Ingrese título" />
                              <small className="form-text text-muted">Campo obligatorio. Máximo 64 caracteres.</small>
                              {this.validator.message('titulo', this.state.titulo, 'required')}
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className={"form-group "+(this.validator.fieldValid('slug') ? "" : ((this.state.startValid) ? "is-invalid": ""))}>
                              <label for="" className="control-label">Slug</label>
                              <input type="text" autoComplete="off" name="slug" className="form-control input-sm" value={this.state.slug} onChange={this.setStateFromInput} placeholder="Ingrese slug" />
                              <small className="form-text text-muted">Campo obligatorio. Máximo 64 caracteres.</small>
                              {this.validator.message('slug', this.state.slug, 'required')}
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="form-group">
                              <label for="" className="control-label">Imagen</label><br />
                              <button className="btn btn-primary btn_open_file" data-id="txtfileslider">
                                  <i className="fas fa-cloud-upload-alt"></i>&nbsp;
                                  Seleccione imagen
                              </button>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="" className="control-label">&nbsp;</label><br />
                          <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> Guardar</button> &nbsp;
                          <button className="btn btn-danger"><i className="fa fa-times"></i> Cancelar</button>
                        </div>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-md-8 col-sm-12 pb-2">
                  <img src={this.state.image} id="img_reader_slider" className="img img-fluid img-thumbnail" alt="myImage"/>
                  <div style={{display:'none'}}>
                    <input type="file" id="txtfileslider" accept="image/x-png,image/jpeg" />
                  </div>
              </div>
          </div>
        </form>
      </AdminLayout>
    )
  }
}
