import React, { Component } from 'react'
import AreasSocialesSlick from '../../components/web/AreasSocialesSlick'
import bgAreasSociales from "./../../assets/images/promociones/promo-bg.png"
import { SliderService, IndicatorService } from '../../components/services';

export default class AreasSociales extends Component {
  constructor(props) {
    super(props);
    this.sliderService = new SliderService();
    this.indicatorService = new IndicatorService();
    this.indicators = [];
    this.state = {
      slides: [],
      indicators: [],
      indicatorRepository: []
    };
    this.slides = [];
    this.indicatorRepository = []
  }
  getSlidersData() {

    this.sliderService.get().then(({data}) => {
      const sliders = data

      const __getSlides = (title) => {
        return sliders.find(slider => slider.title == title).images
      }

      const __getSliderIndicators = (title) => {
        return __getSlides(title).map(({indicator}) => indicator)
      }

      this.setState(state => (state.slides.sociales = __getSlides('Areas sociales'), state))
      this.setState(state => (state.indicators.sociales = __getSliderIndicators('Areas sociales'), state))

    }).catch((error) => {
      console.log(error)
    });
    this.indicatorService.get().then(({data}) => {
      this.setState({indicatorRepository: data})
    }).catch((error) => {
      console.log(error)
    });
  }
  componentWillMount(){
    //console.log(this)
    this.getSlidersData()
  }
  componentDidMount = () =>{
      document.title = "Épiqe - "+this.props.title
  }
  render() {
    return (
      <div className="bg-areasSociales" style={{backgroundImage:`url(${bgAreasSociales})`}}>
            <AreasSocialesSlick slides={this.state.slides.sociales} indicators={this.state.indicators.sociales} indicatorRepository={this.state.indicatorRepository} />
      </div>
    )
  }
}
