import React, { Component } from 'react'
import AdminLayout from "../../components/other/AdminLayout"

export default class TipologiasUpdate extends Component {
  render() {
    return (
            <AdminLayout title={this.props.title} subtitle={this.props.subtitle} consideracion>
                <div className="row">
                    <div className="col-md-12 col-sm-12 pb-5">
                        <button className="btn btn-primary btn-sm pull-right" style={{marginRight:'10px'}}><i className="fa fa-save"></i> Actualizar</button>
                        <button type="button" onClick={() => window.history.back()} className="btn btn-danger btn-sm pull-right"><i className="fa fa-ban"></i> Cancelar <span className="text-meses"></span></button>
                        <hr></hr>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="control-label">Título</label>
                                    <input type="text" name="title" className="form-control input-sm" placeholder="Ingrese título" value="" />
                                    <small className="form-text text-muted">Campo obligatorio. Máximo 64 caracteres.</small>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="control-label">Metraje</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control input-sm" id="validationCustomUsername" placeholder="Metraje" aria-describedby="inputGroupPrepend" required="" />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="inputGroupPrepend">m<sup>2</sup></span>
                                        </div>
                                    </div>
                                    <small className="form-text text-muted">Campo obligatorio..</small>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="selected-image_desktop" className="btn btn-primary"><span className="fa fa-cloud-upload-alt"></span>&nbsp;&nbsp;Añadir fotografía(s)</label>
                                <input id="selected-image_desktop" className="custom-file-input input-hidden" type="file" lang="es" name="project_photos[image][]" />
                                <img alt="" src="https://epiqe.pe/images/tipologia-1.a7044cd9498918a5001fe2910b2e00d6.jpg" id="img_reader_slider" className="img img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
    )
  }
}
