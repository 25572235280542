import React, { Component } from 'react'
import {Link} from "react-router-dom"
import AdminConsideracion from "../../components/other/AdminConsideracion"
import {SliderService, IndicatorService} from "../../components/services"
import AdminLayout from "../../components/other/AdminLayout"


export default class Slider extends Component {
    constructor(props){
        super(props);
        this.sliderService = new SliderService();
        this.indicatorService = new IndicatorService()
        this.state = {
            render: false,
            sliders: [],
        };
    }
    handleDelete(e, slider, slide_id) {
        //this.state.sliders.map(({images}) => images.filter(slide => slide.id != slide_id))
        if (window.confirm('¿Estas seguro? esta acción no se puede revertir.')){
            this.sliderService.deleteSlide(slide_id).then(({data})=>{
                this.setState(({sliders}) => this.state.sliders.map(slider => {
                    slider.images = slider.images.filter(image => image.id != slide_id)
                    return slider
                }))
                console.log(data)
                alert('Slide eliminado')
            }).catch(({response})=>{
                console.log(response)
                alert('Error!')
            })
        }
    }
    componentDidMount(){
        // this.setState({sliders: this.sliderService.get()})
        this.sliderService.get().then(({data}) => {
            this.setState({sliders:  data})
        }).catch((error) => {
            console.log(error)
        });
        
        if(this.state.sliders != undefined){
            this.setState({render: true})
        }
    }
    render() {
        if (this.state.render) {
            return (
                <AdminLayout className="styles_admin content_admin_slider" title={this.props.title} subtitle={this.props.subtitle} consideracion>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 pb-5">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {this.state.sliders.map((slider, i) => 
                                    <li className="nav-item">
                                        <a className={"nav-link " + (i == 0 ? 'active show': '')} id={slider.title + "-tab"} data-toggle="tab" href={"#slider-" + slider.id} role="tab" aria-controls={"#slider-" + slider.id} aria-selected="false">{slider.title}</a>
                                    </li>
                                )}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                {this.state.sliders.map((slider, i) => 
                                    <div className={"tab-pane fade " + (i == 0? "show active" : "") } id={"slider-" + slider.id} role="tabpanel" aria-labelledby={slider.title + "-tab"}>
                                            <div className="row row_button">
                                                <Link to={"/admin/slider/" + slider.id} className="btn btn-primary btn-sm pull-right"><i className="fa fa-plus"></i> Agregar imagenes</Link>
                                                <button className="btn btn-sm btn-primary" style={{marginLeft:'10px'}}><i className="fa fa-save"></i> Actualizar prioridad</button>
                                            </div>                     
                                            <div className="row sortable_card1">
                                                {slider.images.map((image, i) => 
                                                    <div className="col-md-4 pb-2 container-card">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <b>Posición {++i}</b>
                                                            </div>
                                                            <img className="card-img-top" src={`/media/${image.landscape_image}.jpg`} alt="Card image cap"></img>
                                                            <div className="card-body">
                                                                <h5 className="card-title">{image.title}</h5>
                                                                <h6 className="card-title"><i>{image.indicator != undefined ? image.indicator.title : ''}</i></h6>
                                                                <h6 className="card-title"><i><small>{image.legal_disclaimer != undefined ? '* ' + image.legal_disclaimer : ''}</small></i></h6>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <a href={`/media/${image.landscape_image}.jpg`} className="btn btn-secondary btn-sm" target="_blank" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Abrir en nueva pestaña"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a>
                                                                    <Link to={`/admin/slider/imagen/${image.id}/editar`} className="btn btn-secondary btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Actualizar fotografía"><i className="fas fa-edit" aria-hidden="true"></i></Link>
                                                                    <a download href={`/media/${image.landscape_image}.jpg`} className="btn btn-secondary btn-sm" target="_blank" download="" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Descargar"><i className="fa fa-download" aria-hidden="true"></i></a>
                                                                    <a onClick={(e) =>this.handleDelete(e, slider, image.id)} className="btn btn-danger btn-sm" data-container="body" data-toggle="tooltip" data-placement="bottom" title="Eliminar"><i className="fas fa-trash" aria-hidden="true"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}   
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </AdminLayout>
              )
        } else {
            return (<div></div>)
        }
     
    }
  }
  
