import React, { Component } from 'react'

export default class ImageForm extends Component {
    constructor(props){
        super(props);
    }
  render() {
    return (
        <form onSubmit={this.props.submitHandler}>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <div>
                        <p><b>A considerar</b><br /><small>Ningún cambio es guardado sin antes presionar el botón Guardar.<br />Mover los items para ajustar el ordenamiento del item.</small></p>
                        <hr />
                    </div>
                    <button type="submit" className="btn btn-primary btn-sm pull-right" style={{marginRight:'10px'}}><i className="fa fa-save"></i> Guardar</button>
                    <button type="button" onClick={() => window.history.back()} className="btn btn-danger btn-sm pull-right"><i className="fa fa-ban"></i> Cancelar <span className="text-meses"></span></button>
                    <hr/>
                    <label htmlFor="selected-images" className="btn btn-primary"><span className="fa fa-cloud-upload-alt"></span>&nbsp;&nbsp;Añadir fotografía(s)</label>
                    <input id="selected-images" onChange={this.props.handleImagePreview} ref="file" className="custom-file-input input-hidden" type="file" lang="es" name="images[]" multiple="multiple" accept="image/png, image/jpeg, image/webp, image/svg+xml"></input>
                </div>
            </div>
            <div className="row pb-2 content-images-newslider" ref={this.props.refr}>
                {this.props.fields}
            </div>
        </form>
    )
  }
}
