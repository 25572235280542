import React, { Component } from 'react'
// import SubidaImages from "../../dinamics/ImagesAdd"
import AdminImageManager from "./ImageManager"
import ImageForm from "./ImageForm"
import {previewImages} from "../../components/admin/previewImages"
import {PromotionService} from "../../components/services"
import NotFound from "../../components/common/NotFound"

export default class PromotionNew extends Component {
    constructor(props){
        super(props);
        this.promotionService = new PromotionService();
        this.state = {
            fields: this.contentImages,
            view: false,
            payload : {}
        };
        this.cards = [];
        this.images = [];
        this.refFile = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.contentImages = this.refs.contentImages;
        this.handleImagePreview = this.handleImagePreview.bind(this);
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = e => {
        e.preventDefault();
        document.querySelectorAll('.card').forEach((card, i, array) => {
            console.log(array)
            const payload = new FormData()
            card.querySelectorAll('input').forEach((input, i)=>{
                if(input.value){
                    payload.append(input.name, input.value)
                }
            })
            payload.append('image', this.images[i])
            console.log(...payload)
            this.promotionService.create(payload)
                .then((result)=>{
                    console.log(result)
                    alert("Registro exitoso")
                }).catch((result)=>{
                    console.log(result.response)
                    alert("Error!, intentelo más tarde")
                })
        })

    }
    handleImagePreview(e){
        Object.entries(e.target.files).forEach(
            ([key, value]) => {
                this.images.push(value)
            }
        )
        console.log(this.images)
        previewImages(e).map((blob, i)=>{
            let index = this.cards.length;
             this.cards.push(<div className={"col-lg-6 col-md-6 col-sm-12 pb-2 container-card componente-"}>
                <div className="card">
                    <img alt="" className="img img-fluid card-img-top" src={blob} />
                    <div className="card-body">
                        <div>
                            <div className="form-group">
                                <input required className="form-control input-sm" name={"title"} placeholder="Título (Opcional)"  onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <input required className="form-control input-sm" name={"slug"} placeholder="Slug (Opcional): Ej. promo-uno" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <a href={blob} className="btn btn-secondary btn-sm" target="_blank" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Abrir en nueva pestaña" data-original-title="" title=""><i className="fas fa-external-link-alt" aria-hidden="true"></i></a>
                            <button onClick={(e) => this.DeleteImages(e)} className="btn btn-danger btn-sm btn-remove" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Eliminar" data-original-title="" title=""><i className="fas fa-trash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>)
            this.setState({
                fields: this.cards
             });
        });
    }
  render() {
        // if (this.state.view) {
            return (
                <AdminImageManager title={"Crear promociones"}>
                    <ImageForm
                        submitHandler={this.handleSubmit}
                        handleImagePreview={this.handleImagePreview}
                        fields={this.state.fields}
                        refr={this.refFile}>
                    </ImageForm>
                </AdminImageManager>
            )
        // }else{
        //     return (<NotFound/>) 
        // }
  }
}
