import React, { Component } from 'react'
import { Controller, Scene } from 'react-scrollmagic';
import OwlCarousel from 'react-owl-carousel2';
import MediaQuery from 'react-responsive';

/*ATRIBUTOS PURPURA*/
import AtribUbicacionPurple from "./../../assets/svg/atrib_ubicacion_purple.svg"
import AtribPanoramica from "./../../assets/svg/atrib_panoramica.svg"
import AtribTipologia from "./../../assets/svg/atrib_tipologia.svg"
import AtribRespaldo from "./../../assets/svg/atrib_respaldo.svg"
import AtribAcabados from "./../../assets/svg/atrib_acabados.svg"
import AtribAreascomunes from "./../../assets/svg/atrib_areascomunes.svg"
import AtribProyecto from "./../../assets/svg/atrib_proyecto.svg"
import iconAsensores from "./../../assets/svg/iconAscensor.svg"

/*ATRIBUTOS BLANCO*/
import AtribUbicacion from "./../../assets/svg/atrib_ubicacion.svg"
import AtribPanoramicaBlanco from "./../../assets/svg/atrib_panoramica_blanco.svg"
import AtribTipologiaBlanco from "./../../assets/svg/atrib_tipologia_blanco.svg"
import AtribRespaldoBlanco from "./../../assets/svg/atrib_respaldo_blanco.svg"
import AtribAcabadosBlanco from "./../../assets/svg/atrib_acabados_blanco.svg"
import AtribAreascomunesBlanco from "./../../assets/svg/atrib_areascomunes_blanco.svg"
import AtribProyectoBlanco from "./../../assets/svg/atrib_proyecto_blanco.svg"
import iconAscensorBlanco from "./../../assets/svg/iconAscesorInactive.svg"


/*IMAGENES SLIDER*/
import ImgAcabados from "./../../assets/images/atributos/Imagen-Acabados.png"
import ImgAreasComunes from "./../../assets/images/atributos/Imagen-AreasComunes.png"
import ImgCentrico from "./../../assets/images/atributos/Imagen-Centrico.png"
import ImgDistribucion from "./../../assets/images/atributos/Imagen-Distribucion.png"
import ImgPanoramica from "./../../assets/images/atributos/Imagen-Panoramica.png"
import ImgRespaldo from "./../../assets/images/atributos/Imagen-Respaldo.png"
import ImgSostenible from "./../../assets/images/atributos/Imagen-Sostenible.png"
import ImgAscensores from "./../../assets/images/atributos/imgAscensores.jpg"

import NameAtributo from "./../../assets/svg/nameAtributo.svg"
import floatingAtrib1 from "./../../assets/svg/floatingAtrib1.svg"
import floatingAtrib2 from "./../../assets/svg/floatingAtrib2.svg"
import floatingAtrib3 from "./../../assets/svg/floatingAtrib3.svg"

const options = {
    items: 3,
    nav: true,
    center:true,
    margin:35,
    dots:false,
    navText: [
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em"><path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>',
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em"><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>'
    ],
    loop:true,
    rewind: true,
    autoplay: false,
    responsive : {
        0 : { 
            items:2,
            padding:50,
            margin:80 
        },
        750 : { items:3 },
    }
};

const events = {
    
};

export default class Atributos extends Component {
    render() {
        return (
            <Controller>
                <Scene classToggle={[".atributos","moveAtributos"]} triggerElement=".trigger_atributos" reverse={true} indicators={false}>
                    <div className="content-atributos">
                        <MediaQuery query="(min-width: 550px)">
                            <div className="trigger trigger_atributos"></div>
                        </MediaQuery>
                        <div className="container-atributos">
                            <h1 className="generic-green-h1 text-white">Todo lo que necesitas <br />en un solo lugar</h1>
                            
                            <div className="carouselAtributos">
                                <OwlCarousel ref="atributos" options={options} events={events} className="owl-carousel myCarouselAtributos owl-theme owl-loaded owl-drag">
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgCentrico} 
                                        data-exthumbimage={ImgCentrico}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgCentrico})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${AtribUbicacion})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>Ubicación céntrica</span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${AtribUbicacionPurple})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgPanoramica} 
                                        data-exthumbimage={ImgPanoramica}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgPanoramica})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${AtribPanoramicaBlanco})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>Vista panorámica</span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${AtribPanoramica})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgDistribucion} 
                                        data-exthumbimage={ImgDistribucion}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgDistribucion})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${AtribTipologiaBlanco})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>De 40 m<sup>2</sup> hasta 78 m<sup>2</sup></span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${AtribTipologia})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgRespaldo} 
                                        data-exthumbimage={ImgRespaldo}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgRespaldo})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${AtribRespaldoBlanco})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>Respaldo internacional</span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${AtribRespaldo})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgAcabados} 
                                        data-exthumbimage={ImgAcabados}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgAcabados})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${AtribAcabadosBlanco})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>Acabados de primera</span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${AtribAcabados})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgAreasComunes} 
                                        data-exthumbimage={ImgAreasComunes}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgAreasComunes})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${AtribAreascomunesBlanco})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>Áreas comunes exclusivas</span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${AtribAreascomunes})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgSostenible} 
                                        data-exthumbimage={ImgSostenible}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgSostenible})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${AtribProyectoBlanco})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>Proyecto sostenible</span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${AtribProyecto})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        className="ambiente-selector" 
                                        data-src={ImgSostenible} 
                                        data-exthumbimage={ImgSostenible}
                                        title={""}
                                    >
                                        <div className="card">
                                            <div className="cardImage" style={{backgroundImage:`url(${ImgAscensores})`}}>
                                                <div className="iconInactive" style={{WebkitMaskImage:`url(${iconAscensorBlanco})`}}></div>
                                                <div className="iconActive">
                                                    <div className="imgName" style={{backgroundImage:`url(${NameAtributo})`}}>
                                                        <span>Proyecto con 3 ascensores</span>
                                                    </div>
                                                    <div className="imgIconRepresentacion" style={{backgroundImage:`url(${iconAsensores})`}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                        <div className="element_top">
                            <div className="content_element_top">
                                <div className="section-floating-things project">
                                    <div className="section-floating-things-container">
                                    <Controller>
                                        <Scene duration={700} >
                                            {(progress) => (
                                            <div>
                                                    <div id="pat-03" className="floating-thing" style={{left:'calc(42.9522% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib1} inline="true" alt="mySvg" />
                                                    </div>
                                                    <div id="pat-04" className="floating-thing" style={{left:'calc(9.03317% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib2} inline="true" alt="mySvg" />
                                                    </div>
                                                    <div id="pat-05" className="floating-thing" style={{left:'calc(23.4177% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib2} inline="true" alt="mySvg" />
                                                    </div>
                                                    <div id="pat-06" className="floating-thing" style={{left:'calc(92.4328% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib2} inline="true" alt="mySvg" />
                                                    </div>
                                                    <div id="pat-07" className="floating-thing" style={{left:'calc(68.7862% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib3} inline="true" alt="mySvg" />
                                                    </div>
                                                    <div id="pat-08" className="floating-thing" style={{left:'calc(75.7611% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib2} inline="true" alt="mySvg" />
                                                    </div>
                                                    <div id="pat-09" className="floating-thing" style={{left:'calc(3.78044% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib2} inline="true" alt="mySvg" />
                                                    </div>
                                                    <div id="pat-10" className="floating-thing" style={{left:'calc(-16.2137% + '+(progress * 15)+'% )'}}>
                                                        <img src={floatingAtrib2} inline="true" alt="mySvg" />
                                                    </div>
                                            </div>
                                            )}
                                        </Scene>
                                    </Controller>
                                    </div>
                                </div>
                                <div className="techo"></div>
                            </div>
                        </div>
                    </div>
                </Scene>
            </Controller>
        )
    }
}
