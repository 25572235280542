import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import SimpleLightbox from './simpleLightbox'

export default class Carousel extends Component {
    constructor(props){
        super(props);
    }
    componentDidMount = () =>{
        new SimpleLightbox({
            elements: '.myCarouselAvanceObra-'+this.props.id+' .owl-stage .owl-item a',
            loadingCaption: 'Cargando imagen...',
            nextBtnCaption: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 444.815 444.815" style="enable-background:new 0 0 444.815 444.815;" xml:space="preserve"><g><path d="M421.976,196.712L236.111,10.848C228.884,3.615,220.219,0,210.131,0c-9.9,0-18.464,3.615-25.697,10.848L163.023,32.26   c-7.234,6.853-10.85,15.418-10.85,25.697c0,10.277,3.616,18.842,10.85,25.697l83.653,83.937H45.677   c-9.895,0-17.937,3.568-24.123,10.707s-9.279,15.752-9.279,25.837v36.546c0,10.088,3.094,18.698,9.279,25.837   s14.228,10.704,24.123,10.704h200.995L163.02,360.88c-7.234,7.228-10.85,15.89-10.85,25.981c0,10.089,3.616,18.75,10.85,25.978   l21.411,21.412c7.426,7.043,15.99,10.564,25.697,10.564c9.899,0,18.562-3.521,25.981-10.564l185.864-185.864   c7.043-7.043,10.567-15.701,10.567-25.981C432.54,211.939,429.016,203.37,421.976,196.712z" fill="#40009c"></path></g></svg>',
            prevBtnCaption: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 444.822 444.822" style="enable-background:new 0 0 444.822 444.822;" xml:space="preserve"><g><path d="M423.265,178.298c-6.19-7.139-14.229-10.707-24.126-10.707H198.148L281.8,83.937c7.234-7.229,10.848-15.891,10.848-25.981   c0-10.085-3.613-18.747-10.848-25.979l-21.413-21.129C253.156,3.619,244.59,0,234.693,0c-10.089,0-18.752,3.615-25.981,10.848   L22.848,196.428c-7.043,7.423-10.564,16.084-10.564,25.981c0,10.089,3.521,18.654,10.564,25.693l185.864,186.152   c7.422,7.043,16.083,10.567,25.981,10.567c10.085,0,18.65-3.524,25.693-10.567l21.413-21.693   c7.234-6.851,10.848-15.42,10.848-25.693c0-10.284-3.613-18.853-10.848-25.7l-83.652-83.939h200.991   c9.896,0,17.936-3.569,24.126-10.707c6.184-7.139,9.274-15.749,9.274-25.838v-36.545   C432.542,194.05,429.448,185.437,423.265,178.298z" fill="#40009c"></path></g></svg>',
        });
    }
    render() {
        const options = {
            items: 1,
            nav: true,
            singleItem:true,
            navText: [
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 444.822 444.822" style="enable-background:new 0 0 444.822 444.822;" xml:space="preserve"><g><path d="M423.265,178.298c-6.19-7.139-14.229-10.707-24.126-10.707H198.148L281.8,83.937c7.234-7.229,10.848-15.891,10.848-25.981   c0-10.085-3.613-18.747-10.848-25.979l-21.413-21.129C253.156,3.619,244.59,0,234.693,0c-10.089,0-18.752,3.615-25.981,10.848   L22.848,196.428c-7.043,7.423-10.564,16.084-10.564,25.981c0,10.089,3.521,18.654,10.564,25.693l185.864,186.152   c7.422,7.043,16.083,10.567,25.981,10.567c10.085,0,18.65-3.524,25.693-10.567l21.413-21.693   c7.234-6.851,10.848-15.42,10.848-25.693c0-10.284-3.613-18.853-10.848-25.7l-83.652-83.939h200.991   c9.896,0,17.936-3.569,24.126-10.707c6.184-7.139,9.274-15.749,9.274-25.838v-36.545   C432.542,194.05,429.448,185.437,423.265,178.298z" fill="#FFFFFF"/></g></svg>',
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 444.815 444.815" style="enable-background:new 0 0 444.815 444.815;" xml:space="preserve"><g><path d="M421.976,196.712L236.111,10.848C228.884,3.615,220.219,0,210.131,0c-9.9,0-18.464,3.615-25.697,10.848L163.023,32.26   c-7.234,6.853-10.85,15.418-10.85,25.697c0,10.277,3.616,18.842,10.85,25.697l83.653,83.937H45.677   c-9.895,0-17.937,3.568-24.123,10.707s-9.279,15.752-9.279,25.837v36.546c0,10.088,3.094,18.698,9.279,25.837   s14.228,10.704,24.123,10.704h200.995L163.02,360.88c-7.234,7.228-10.85,15.89-10.85,25.981c0,10.089,3.616,18.75,10.85,25.978   l21.411,21.412c7.426,7.043,15.99,10.564,25.697,10.564c9.899,0,18.562-3.521,25.981-10.564l185.864-185.864   c7.043-7.043,10.567-15.701,10.567-25.981C432.54,211.939,429.016,203.37,421.976,196.712z" fill="#FFFFFF"/></g></svg>'
            ],
            loop:true,
            autoplay: true
        };
        const events = {
            onInitialized: function(event){
                
            },
            onChanged: function(event) {

            }
        };
        return (
            <div style={{position:'relative'}}>
                <OwlCarousel options={options} events={events} className={"content-carousel owl-carousel owl-theme owl-loaded owl-drag myCarouselAvanceObra-"+this.props.id}>
                    {
                        this.props.images.map((fill,index) => 
                            <a href={fill.url} >
                                <div key={index} className="tl-image" data-src={fill.url} data-position={fill.position} data-title={this.props.title} data-content={this.props.id} style={{backgroundImage: `url(${fill.url})`}}></div>
                            </a>
                        )
                    }
                </OwlCarousel>
                {/* <div className="svg_fecha" style={{backgroundImage: `url(${SVGFecha})`}}></div> */}
                <div className="texto_fecha">{this.props.title}</div>
            </div>
        )
    }
}
