import React from 'react';
import Inicio from "../pages/web/Inicio"
import Departamentos from "../pages/web/Departamentos"
import AreasSociales from "../pages/web/AreasSociales"
import Vivepiqe from "../pages/web/Vivepiqe"
import Promociones from "../pages/web/Promociones"
import Respaldo from "../pages/web/Respaldo"
import DefiPromo from "../pages/web/DefiPromo"
import ThankYou from "../pages/web/ThankYou"
import WorkProgress from "../pages/web/WorkProgress"
import Reclamacion from './web/reclamacion/reclamacion';

export const frontInicio = () => { return (<Inicio title="Inicio"/>) }
export const frontDepartamentos = (fase) => {
    const myFase = fase.route.match.params.fase;
    return (<Departamentos title="Departamentos" fase={myFase}/>)
};
export const frontAreasSociales = () => {return (<AreasSociales title="Areas sociales"/>) };
export const frontVivepiqe = () => {return (<Vivepiqe title="Vive Épiqe"/>) };
export const frontPromociones = () => {return (<Promociones title="Promociones"/>) };
export const frontRespaldo = () => {return (<Respaldo title="Respaldo"/>) };
export const frontThankYou = () => {return (<ThankYou title="Gracias"/>) };
export const workProgress = () => <WorkProgress title="Avance de Obra"/>
export const complaintsBook = () => <Reclamacion title="Libro de reclamaciones"></Reclamacion>
export const frontDefiPromo = (slug) => {
    const mySlug = slug.route.match.params.slug;
    return (<DefiPromo slug={mySlug} />)
}

