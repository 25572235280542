import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
// import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from "./serviceWorker"
import Routes from './routes/routes';
import userReducer from './components/admin/user/reducer';
import {gtm} from "./components/google/gtag";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-photoswipe/dist/photoswipe.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import $ from "jquery"

window.$ = $
window.jQuery = $


const store = createStore(
    userReducer,
);

const preloadFont = path => {
  const link = document.createElement('link');
  link.rel = "preload"
  link.as = "font"
  link.href = path;
  link.type = "font/woff2"
  link.crossOrigin = "crossorigin"
  link.style.cssText  = "font-display:swap;"
  document.head.appendChild(link);
}

preloadFont(require('slick-carousel/slick/fonts/slick.woff'))
preloadFont(require('@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2'))
preloadFont(require('./assets/fonts/Biko.woff2'))
preloadFont(require('./assets/fonts/Biko-Bold.woff2'))
preloadFont(require('./assets/fonts/Gotham-Book.woff2'))
preloadFont(require('./assets/fonts/GothamRounded-Bold.woff2'))
preloadFont(require('./assets/fonts/GothamRounded-BoldItalic.woff2'))

ReactDOM.render(
    <Provider store={store} >
      <Routes />
    </Provider>,
    document.getElementById('root')
)
// registerServiceWorker();
serviceWorker.unregister();
