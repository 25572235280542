import React, { Component } from 'react'
import { Link, NavLink } from "react-router-dom"

export default class AdminHeader extends Component {
  constructor(props) {
      super(props);
  }
  render() {
    return (
      <div>
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
          <div className="container">
            <Link to="/admin/dashboard" className="navbar-brand">Épiqe</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink to="/admin/dashboard" activeStyle={{color:'#fff'}} className="nav-link">Inicio</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/admin/slider" activeStyle={{color:'#fff'}} className="nav-link">Slider</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/admin/promociones" activeStyle={{color:'#fff'}} className="nav-link">Promociones</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/admin/tipologias" activeStyle={{color:'#fff'}} className="nav-link">Tipologías</NavLink>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" href="/" rel="noopener noreferrer" target="_blank">Ver sitio</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="javascript:;" id="list-leads-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      {this.props.username}
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="list-leads-dropdown">
                      <a className="dropdown-item" href="#" onClick={() => {this.props.userActions.logout()}} >Salir</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>    
      </div>
    )
  }
}
