import React, { Component } from 'react'
import "./reclamacion.scss"
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import { MailService } from '../../services';
import { cmBook } from '../../mail/cmBook';

export default class Reclamacion extends Component {
    constructor() {
        super()
        this.mailService = new MailService()
        this.validator = new SimpleReactValidator();
    }
    submitForm = async (e) => {
        e.preventDefault()
        if (!this.validator.allValid()) {
            this.setState({startValid: true})
            return
        }

        try {
            await this.mailService.from("Proyecto Épiqe <no-reply@epiqe.pe>")
                .to("Informes@cosapiinmobiliaria.com.pe")
                .subject("Libro de reclamaciones")
                .html(cmBook(this.state))
                .send()

            Swal.fire({
                title: '',
                text: 'Mensaje enviado',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'red'
            })
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: '',
                text: '¡Error! Intentelo más tarde',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'red'
            })
        }
        
    }
    state = {
        startValid: false,
        fname: "",
        lname: "",
        mlname: "",
        email: "",
        phone: "",
        idType: "",
        idNumber: "",
        region: "",
        province: "",
        district: "",
        address: "",
        typeOfGood: "",
        orderNumber: "",
        amount: "",
        goodDescription: "",
        complaintType: "",
        complaintDetail: "",
        consumerPetiton: "",
        sellerAction: "",
        terms: true
    }

    setInputProps = (classes, field, rules) => {
        this.validator.message(field, this.state[field], rules)
        return {
            name: field,
            value: this.state[field],
            className: `${classes} ${(this.validator.fieldValid(field) ? "" : (this.state.startValid) ? "invalid": "")}`,
            onChange: this.setStateFromInput,
        }
    }

    setStateFromInput = event => this.setState({
        [event.target.name]: event.target.value
    })

    componentDidMount(){
    }
    render() {
        return (
            <main className="page --complaints-book">
                <form onSubmit={this.submitForm}>
                    <div className="form-group col-xs-12">
                        <h1 className="tit-libro text-left text-center text-uppercase">Libro de Reclamaciones</h1>
                        <p className="descripcion color-blanco text-left tit-custom-dos">Conforme a lo establecido en el Código de
                            Protección y Defensa del Consumidor, esta institución cuenta con un Libro de Reclamaciones a su
                            disposición.
                        </p>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "fname", "required")} placeholder="Nombre*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "lname", "required")} placeholder="Apellido Paterno*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "mlname", "required")} placeholder="Apellido Materno*" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-3 col-lg-3">
                            <input type="email" {...this.setInputProps("form-control large", "email", "email|required")} placeholder="Correo Electrónico*" />
                        </div>
                        <div className="form-group col-sm-3 col-lg-3">
                            <input type="tel" {...this.setInputProps("form-control large", "phone", "required")} placeholder="Celular*" />
                        </div>
                        <div className="form-group col-sm-3 col-lg-3 no-padding-right no-padding-left">
                            <select {...this.setInputProps("form-control", "idType", "required")}>
                                <option value="">Seleccionar</option>
                                <option value="DNI">DNI</option>
                                <option value="RUC">RUC</option>
                                <option value="Carnet de Extranjería">Carnet de Extranjería</option>
                            </select>
                        </div>
                        <div className="form-group col-sm-3 col-lg-3 no-padding-right no-padding-left">
                            <input type="text" {...this.setInputProps("form-control large", "idNumber", "required")} placeholder="N° documento" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "region", "required")} placeholder="Departamento*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "province", "required")}  placeholder="Provincia*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "district", "required")} placeholder="Distrito*" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <input type="text" {...this.setInputProps("form-control large", "address", "required")} placeholder="Dirección*" />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <h2 className="text-left">IDENTIFICACIÓN DEL BIEN CONTRATADO</h2>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-4 col-lg-4">
                            <select {...this.setInputProps("form-control", "typeOfGood", "required")}>
                                <option value="" selected="">Tipo de Bien Contratado*</option>
                                <option value="Producto">Producto</option>
                                <option value="Servicio">Servicio</option>
                            </select>
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "orderNumber", "required")} placeholder="Nº de Pedido*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...this.setInputProps("form-control large", "amount", "required")} placeholder="Monto*" />
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea type="text" {...this.setInputProps("form-control large textarea", "goodDescription", "required")} placeholder="Descripción*"  />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <h2 className="text-left">DETALLE DE LA RECLAMACIÓN</h2>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <select {...this.setInputProps("form-control", "complaintType", "required")} >
                                <option value="">Tipo de Reclamo*</option>
                                <option value="Reclamo">Reclamo</option>
                                <option value="Queja">Queja</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <input type="text" {...this.setInputProps("form-control large", "consumerPetiton", "required")} placeholder="Pedido del Consumidor*"  />
                        </div>
                    </div>
                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <textarea type="text" {...this.setInputProps("form-control large textarea", "complaintDetail", "required")} placeholder="Descripción*" />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <h2 className="text-left">ACCIONES ADOPTADAS POR EL VENDEDOR</h2>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <textarea type="text" {...this.setInputProps("form-control large textarea", "sellerAction", "required")} placeholder="Descripción*"  />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <div className="col-xs-12">
                            <div className="checkbox">
                                {/* <input type="checkbox" {...this.setInputProps("form-control large", "mlname", "required")} name="politicas" value="politicas aceptadas" /> */}
                                <label for="politicas" className="check">
                                <input type="checkbox" checked/>
                                &nbsp;He leído y acepto la&nbsp;<a target="_blank" href="./politica-de-privacidad.pdf" class="Primary">Política de Datos Personales</a>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-xs-12 flex-center">
                        <button type="submit" className="form-submit btnSubmitLibro">Enviar</button>
                    </div>

                    <div className="block-answer col-xs-12">
                        <div className="values">
                            <div className="error-message">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </form>
            </main>
        )
    }
}
