export const promotions = [
    {
        "name": "Desembolso",
        "slug": "desembolso",
        "image": require("../assets/images/promociones/Epiqe-Desembolso-02.png")
    },
    {
        "name": "Invierte tu AFP",
        "slug": "invierte-afp",
        "image": require("../assets/images/promociones/afp.jpeg")
    },
    {
        "name": "Promo caduca",
        "slug": "",
        "image": require("../assets/images/promociones/mothers-day.jpg")
    },
    {
        "name": "Promo caduca",
        "slug": "",
        "image": require("../assets/images/promociones/vuelavuela.jpg")
    }
]