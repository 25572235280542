import React, { Component } from 'react'
//import SubidaImages from "../../dinamics/ImagesAdd"
import AdminImageManager from "./ImageManager"
import ImageForm from "./ImageForm"
import {previewImages} from "../../components/admin/previewImages"
import {IndicatorService} from "../../components/services"
import NotFound from "../../components/common/NotFound"

export default class AddIndicator extends Component {
    constructor(props){
        super(props);
        this.indicatorService = new IndicatorService();
        this.state = {
            fields: this.contentImages,
            view: false,
            indicator : []
        };
        this.cards = [];
        this.images = [];
        this.refFile = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.contentImages = this.refs.contentImages;
        this.handleImagePreview = this.handleImagePreview.bind(this);
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = e => {
        e.preventDefault();
        document.querySelectorAll('.card').forEach((card, i, array) => {
            //console.log(array)
            const payload = new FormData()
            card.querySelectorAll('input').forEach((input, i)=>{
                if(input.value){
                    payload.append(input.name, input.value)
                }
            })
            payload.append('image', this.images[i])
            this.indicatorService.create(payload)
                .then((result)=>{
                    alert("Registro exitoso")
                    //console.log(result)
                }).catch((result)=>{
                    alert("Error!, intentelo más tarde")
                    //console.log(result.response)
                })
        })

    }
    handleImagePreview(e){
        Object.entries(e.target.files).forEach(
            ([key, value]) => {
                this.images.push(value)
            }
        )
        console.log(this.images)
        previewImages(e).map((blob, i)=>{
            let index = this.cards.length;
             this.cards.push(<div className={"col-lg-6 col-md-6 col-sm-12 pb-2 container-card componente-"}>
                <div className="card">
                    <img className="img img-fluid card-img-top" src={blob} />
                    <div className="card-body">
                        <div>
                            <div className="form-group">
                                <input className="form-control input-sm" name={"title"} placeholder="Título *" required  onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <a href={blob} className="btn btn-secondary btn-sm" target="_blank" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Abrir en nueva pestaña" data-original-title="" title=""><i className="fas fa-external-link-alt" aria-hidden="true"></i></a>
                            <button onClick={(e) => this.DeleteImages(e)} className="btn btn-danger btn-sm btn-remove" data-container="body" data-toggle="tooltip" data-placement="bottom" data-title="Eliminar" data-original-title="" title=""><i className="fas fa-trash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>)
            this.setState({
                fields: this.cards
             });
        });
    }
    /*componentWillMount = () =>{
        this.indicatorService.get()
        .then((indicator)=>{
            this.setState({view: true, indicator: indicator.data})
        }).catch((error)=>{
            console.log(error)
            this.setState({view:false})
        })
    }*/
  render() {
        //if (this.state.view) {
            return (
                <AdminImageManager title={`Crear indicador`}>
                    <ImageForm
                        submitHandler={this.handleSubmit}
                        handleImagePreview={this.handleImagePreview}
                        fields={this.state.fields}
                        refr={this.refFile}>
                    </ImageForm>
                </AdminImageManager>
            )
        /*}else{
            return (<NotFound/>) 
        }*/
  }
}
