import React, { Component } from 'react'
import "./dialog.scss"
import $ from "jquery"
export default class Dialog extends Component {
    componentDidMount(){
    $(document).ready(function(){
        let delay =  setInterval(() => {
            if(document.querySelector(".content-header-fe")){ 
                document.querySelector(".content-header-fe").addEventListener("click",function(){
                    opacity()
                })
            }
            clearInterval(delay)
        }, 300);
        $(".Dialog").click(function(e){
            if(e.target.id == "dialog"){
                opacity()
            }
        })
        function opacity(){
            $(".Dialog").addClass("opacity")
        }
    })
    }
    render() {
        return (
            <div className="Dialog" id="dialog">
                <img className="add reboteX" src={require("../../../assets/images/atributos/modal-menu.png")}></img>
            </div>
        )
    }
}
