import React, { Component } from 'react'
import BackgroundImage from 'react-background-image-loader';

export default class ItemEligeDepa extends Component {
    render() {
        return (
            <div className="col-sm-12 col-md-6">
                <div className="item-tipologia" data-position={this.props.position} onClick={this.props.evento}>
                    <div className="img-tipologia" >
                        <div className="information">
                            <span className="icono"><i className="fas fa-search"></i></span><br /><span>{this.props.name}</span><br /><span>Área: {this.props.metraje}m<sup>2</sup></span>
                        </div>
                        <div className="fondo"></div>
                        <div className="mascara" data-url={this.props.image} style={{backgroundImage: `url(${this.props.image})`}} />
                    </div>
                    <h4>{this.props.dormitorio} ({this.props.metraje} m<sup>2</sup>)</h4>
                </div>
            </div>
        )
    }
}
