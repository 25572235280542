import React, { Component } from 'react'
import {PhotoSwipe} from 'react-photoswipe';
import Slider from 'react-slick';
import MagicSliderDots from 'react-magic-slider-dots';
import { Controller, Scene } from 'react-scrollmagic';
import MediaQuery from 'react-responsive';
import ItemDepartamentos from "./ItemDepartamentos"
import { SliderService } from '../services';
import ArrowSlick from "./ArrowSlick"

export default class DepartamentosSlick extends Component {
    constructor(props){
        super(props);
        this.sliderService = new SliderService();
        this.state = ({
            isOpen:false,
            owlItems: [],
            items:[],
            slides: [],
            options:{},
        })
    }

    fnSetImages = () => {
        var array = [];
        var title = '';
        const $ = window.$
        $.each($(".myCarouselDepartamentos .slick-slide:not(.slick-slide.cloned) .content-image"), function( index, value ) {
            var img = new Image;
            img.src = $(value).find('.myimage').data('url');
            var bgImgWidth = img.width;
            var bgImgHeight = img.height;
            array.push({
                src:$(value).find('.myimage').data('url'),
                w:img.width,
                h:img.height,
            })
        });
        this.setState({
            items:array
        });
    }

    openPhotoSwipe = (e) => {
        e.preventDefault();
        this.fnSetImages();
        var position = parseInt(e.currentTarget.getAttribute('data-position')) - 1;
        this.setState({
          isOpen: true,
          options: {
            index:position,
            closeOnScroll: false
          }
        });
    };

    handleClose = () => {
        this.setState({
          isOpen: false
        });
    };

    componentWillMount = async () =>{
        await this.fnGetSliders()
    }

    fnGetSliders = async () => {
        await this.sliderService.get().then(({data}) => {
            const sliders = data
    
            const __getSlides = (title) => {
                return sliders.find(slider => slider.title == title).images
            }
    
            this.setState(state => (state.slides = __getSlides('Departamentos'), state))
          
            this.state.slides.map((slide, i) => {
                this.setState(({owlItems}) => (owlItems.push(
                    <ItemDepartamentos 
                        key={++i} 
                        position={i}
                        name={slide.title}
                        image={`https://epiqe.pe/media/${slide.landscape_image}.jpg`}
                        legal={slide.legal_disclaimer}
                        evento={this.openPhotoSwipe}
                    />
                ), owlItems))
            }) 
            this.setState({view:true})
            }).catch((error) => {
                console.log(error)
            });
    }

    render() {
        const settings = {
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable:true,
            swipe:true,
            swipeToSlide:true,
            cssEase: 'linear',
            nextArrow: <ArrowSlick/>,
            prevArrow: <ArrowSlick/>,
            appendDots: (dots) => {
                return <MagicSliderDots dots={dots}  numDotsToShow={5} dotWidth={20} />
            },
            afterChange: (event) => {
                const $ = window.$
                let name = $('.myCarouselDepartamentos .slick-slide:nth('+event+') .content-image').data('name');
                let position = $('.myCarouselDepartamentos .slick-slide:nth('+event+') .content-image').data('position');
                let total = $('.myCarouselDepartamentos .slick-slide:not(.slick-slide.cloned)').length;
                $('.name_departamentos').text(name);
                $('.position_departamentos').text(position+'/'+total); 
            }     
        };
        return (
            <Controller>
                <Scene classToggle={[".content-slider-departamentos","moveToUp2"]} triggerElement=".trigger_departamentos" reverse={true} indicators={false}>
                    <div className="page_front content-areas-sociales">
                        <MediaQuery query="(min-width: 550px)">
                            <div className="trigger trigger_departamentos"></div>
                        </MediaQuery>
                        <h1 className="generic-green-h1">Conoce tu nuevo hogar</h1>
                        <h2 className="generic-purple-h2">Confort y modernidad</h2>
                        <br/>
                        <div className="content-slider content-slider-departamentos">
                            <Slider ref={slider => (this.slider = slider)} {...settings} className="myCarouselDepartamentos owl-nav">
                                { this.state.owlItems }
                            </Slider>
                            <div className="contentTextName">
                                <div className="contentBackgroundGreen" style={{backgroundImage:`url(${require("./../../assets/images/nameBackgroundGreen.svg")})`}}/>
                                <div className="contentBackgroundPurple" style={{backgroundImage:`url(${require("./../../assets/images/nameBackgroundPurple.svg")})`}}/>
                                <div className="text-infSlider name_departamentos">Dormitorio principal</div>
                            </div>
                        </div>
                        <PhotoSwipe isOpen={this.state.isOpen} items={this.state.items} options={this.state.options} onClose={this.handleClose}/>
                    </div>
                </Scene>
            </Controller>
        )
    }
}
