import React, { Component } from 'react'

const Consideracion = () => (
    <div>
        <p>
            <b>A considerar</b>
            <br />
            <small className="text-muted">Ningún cambio es guardado sin antes presionar el botón Guardar.</small>
            <br />
            <small className="text-muted">Si no deseas cambiar la foto en sí, no eligas ninguna imágen en dicho campo.</small>
        </p>
        <hr className="my-4" />
    </div>
)

export default class AdminLayout extends Component {
    componentWillMount = () =>{
        document.title = 'Épiqe - '+this.props.title
        console.log(this.props)
    }
    render() {
        const {title,subtitle,children,...props} = this.props;
        return (
            <div {...props}>
                <div className="container">
                    <div className="jumbotron dashboard">
                        <h1 className="display-4">{this.props.title}</h1>
                        <p className="lead">{this.props.subtitle}</p>
                        <hr className="my-4" />
                        { (this.props.consideracion) ? <Consideracion /> : null }
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}
