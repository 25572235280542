import React, { Component } from 'react'
import Isotipo from "./../../assets/svg/isotipo.svg"

export default class Loading extends Component {
    constructor(props) {
        super(props);
        // this.handleLoad = this.handleLoad.bind(this);
    }
    
    componentDidMount() {
        const $ = window.$
        setTimeout(function(){
            $('#loading').fadeOut("slow")
        },1500)
    }
    render() {
        return (
            <div id="loading" align="center" className="loading">
                <div className="loader-container">
                    <img className="pulse-button" src={Isotipo} />
                </div>
            </div>
        )
    }
}
