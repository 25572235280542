import axios from 'axios'
import {
    PROJECT_MODELS_ENDPOINT,
    PROJECT_STAGE_ENDPOINT
} from '../config/endpoints'

/**
 * @author Juan Lopez Tello
 * */
export class ProjectModelService {
    constructor() {
        this.headers = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en,es;q=0.8',
                'Content-Type': `multipart/form-data;`,
              }
        }
    }
    get(id = false) {
        const endpoint = id ? `${PROJECT_MODELS_ENDPOINT}${id}` : PROJECT_MODELS_ENDPOINT;
        return axios.get(endpoint).then(response => response.data);
    }
    create(stageId, model) {
        return axios.post(`${PROJECT_STAGE_ENDPOINT}${stageId}`, model);
    }
    update(model_id, model) {
        return axios.put(`${PROJECT_STAGE_ENDPOINT}${model_id}`, model);
    }
}