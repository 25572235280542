import React, { Component } from 'react'
import "./footer.scss"
export default class Footerfinal extends Component {
    render() {
        return (
            <footer className="footerfinal">
                <div className="flex-row content-column">
                    <div className="column content-subtitle">
                        <span className="d-block GothamRoundedBoldItalic sub-title-1">Comunícate</span>
                        <span className="d-block GothambookItalic sub-title-2">con nosotros</span>
                    </div>
                    <div className="column">
                        <a href="tel:+01748320" target="_blank" className="d-flex"><div className="icon icon-item-1"></div>748-320</a>
                        <a href="https://goo.gl/maps/ZdubzGUXuoS6gEVT9" target="_blank" className="d-flex"><div className="icon icon-item-2"></div>Av. Manuel Olguín 600, Surco</a>
                        <a href="mailto:comercial@epiqe.pe" target="_blank" className="d-flex"><div className="icon icon-item-3"></div>comercial@epiqe.pe</a>

                    </div>
                    <div className="column">
                        <a href="/pdf/Políticas-de-privacidad-de-datos.pdf" target="_blank" className="d-flex">Política de privacidad de datos</a>
                        <a href="/pdf/Código-de-protección-al-consumidor.pdf" target="_blank" className="d-flex">Código de protección al consumidor</a>
                        <a href="/pdf/Terminos-y-condiciones.pdf" target="_blank"className="d-flex">Términos y condiciones</a>
                        <a href="/libro-de-reclamaciones" target="_blank" className="d-flex"><div className="icon icon-item-libro"></div>Libro de reclamaciones</a>

                    </div>
                    <div className="column img">
                        <div className="content-img">
                            <a href="https://www.cosapiinmobiliaria.com.pe/" target="_blank">
                                <img className="cosapi" src={require("../../../assets/images/iconos/cosapi.png")}></img>
                            </a>
                                +
                            <a href="https://gerpal.pe/" target="_blank">
                                <img className="gerpal" src={require("../../../assets/images/iconos/gerpal.png")}></img>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="d-flex line-footer">
                    <div className="rights">© 2020 Proyecto Épiqe. Todos los derechos reservados.</div>
                    <div className="content-brands">
                        <a href="http://adiperu.pe/" target="_blank"><img alt="Logo AdiPeru" className="adiperu" src={require("../../../assets/images/iconos/adiperu.png")}></img></a>
                        <a href="https://www.asei.com.pe/" target="_blank"><img alt="Logo ASEI Asociación de empresas inmobiliarias" src={require("../../../assets/images/iconos/asei-asociacion.png")}></img></a>
                    </div>
                </div>
            </footer>
        )
    }
}
