import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserActions } from './UserActions';
import SimpleReactValidator from "simple-react-validator"
import ReactNotify from "react-notify"

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startValid: false,
            username:'',
            passworde:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.action = new UserActions(this.props.dispatch);
        this.validator = new SimpleReactValidator({
            messages: {
              required: 'Complete el campo'
            },
        });
    }

    showNotification = () =>{
        this.refs.notificator.error("Mensaje", "Usuario invalido.", 4000);
    }

    componentWillMount = () =>{
        document.title = 'Épiqe - login';
    }

    handleSubmit(event) {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () =>{
        if( this.validator.allValid() ){
            if (this.state.username == 'admin' && this.state.passworde == 'admin123') {
                this.action.login()
            }else{
                this.showNotification()
            }
        } else {
            this.setState({ startValid: true })
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setStateFromInput = (event) =>{
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    render() {
        return(
            <div className="content-login">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-login">                    
                        <h1 className="h3 mb-3 font-weight-normal text-center">Iniciar sesión</h1>
                        <div className="form-group">
                            <label htmlFor="username">Nombre de usuario</label>
                            <input type="text" className={"form-control "+(this.validator.fieldValid('username') ? "" : ((this.state.startValid) ? "is-invalid": ""))} placeholder="Nombre de usuario" name="username" value={this.state.username} onChange={this.setStateFromInput} autoComplete="off" autoFocus="true" required=""></input>
                            {this.validator.message('username', this.state.username, 'required')}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Contraseña</label>
                            <input type="password" className={"form-control "+(this.validator.fieldValid('passworde') ? "" : ((this.state.startValid) ? "is-invalid": ""))} placeholder="Contraseña" name="passworde" value={this.state.passworde} onChange={this.setStateFromInput} autoComplete="off" required=""></input>
                            {this.validator.message('passworde', this.state.passworde, 'required')}
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary btn-block mb-5">Ingresar</button>
                        </div>
                        <hr></hr>
                        &copy; Formula Perú - 2018 
                    </div>
                </form>
                <ReactNotify ref='notificator'/>
            </div>
        );
    }

}

function mapStateToProps(state, props) { return { user: state } }
function mapDispatchToProps(dispatch) { return { dispatch }; }

export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
