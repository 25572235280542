import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import anime from 'animejs/lib/anime.es.js';
import ItemBanner from "./ItemBanner";
import video1 from "./../../assets/videos/899134240_1.webm"
import video2 from "./../../assets/videos/899134240_0.webm"
import video3 from "./../../assets/videos/899134240_3.webm"
import video4 from "./../../assets/videos/899134240_2.webm"
import ScreenVideo from "./../../assets/videos/videoScreen.png"
import ScreenVideo1 from "./../../assets/videos/screen1.png"
import ScreenVideo2 from "./../../assets/videos/screen2.png"
import ScreenVideo3 from "./../../assets/videos/screen3.png"
import ScreenVideo4 from "./../../assets/videos/screen4.png"
const $ = window.$
let Onended = '';
export default class CarouselBanner extends Component {
    constructor(props){
        super(props);
        this.state = {
            owlItems:[]
        }
    }

    fnCreateLetterAnimation = () => {
        const $ = window.$
        $.each( $('.ml2'), function( key, value ) {
            value.innerHTML = value.textContent.replace(/\S/g, "<span class='letter'>$&</span>")
        });
    }

    fnCreateAnimation = () => {
        let animation = anime.timeline({
            loop: false,
            autoplay: false,
        })
        .add({
            targets: '.ml2 .letter',
            scale: [2,1],
            opacity: [0,1],
            translateZ: 0,
            easing: "easeOutExpo",
            duration: 1600,
            delay: (el, i) => 15*i
        }).add({
            targets: '.ml2',
            opacity: 0,
            duration: 500,
            easing: "easeOutExpo",
            delay: 500
        });    

        return animation;
    }

    esDispositivoIOS(){
        var userAgent = window.navigator.userAgent;
        return (/iPad|iPhone|iPod/.test(userAgent));
    }

    componentDidMount = () => {
        const $ = window.$
        this.fnCreateLetterAnimation();
        let animation = this.fnCreateAnimation(); 
        if (!this.esDispositivoIOS() && !window.MSStream) {
            $('.myCarouselBanner').css({'display':'none'})
            this.fnShowVideo(animation)    
        }else{
            $('.myVideoEpiqe').css({'display':'none'})
            $('.myCarouselBanner').css({'display':'block'})
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.slides !== prevProps.slides) {
            this.props.slides.map((slide, i) => {
                this.setState(state => (state.owlItems.push(
                    <ItemBanner key={++i} position={i} enlace={slide.link} image_desktop={`https://epiqe.pe/media/${slide.landscape_image}.jpg`} image_responsive={`https://epiqe.pe/media/${slide.portrait_image}.jpg`}/>
                ), state))
            })
        }
    }

    fnShowText = (animation,positionText2) => {
        setTimeout(() => animation.play(), 400)
        console.log(positionText2)
        animation.finished.then(function() {
            const $ = window.$
            $('.contentTextAnimate .context').removeClass('active')
            $('.contentTextAnimate .context:nth('+positionText2+')').addClass('active')
        });
    }

    fnShowVideo = (animation) => {
        var positionText = 0; 
        var promise = document.querySelector('.myVideoEpiqe.active').play();
        if (promise !== undefined) {
            promise.then(_ => {
                setTimeout(function(){
                    animation.play();
                },400)
            }).catch(error => {  });
        }

        document.querySelector('.myVideoEpiqe.active').play({
            onplay: function(){
                setTimeout(function(){
                    animation.play();
                },400)
            },
            onerror:function(errorCode, description){
                console.log(errorCode, description)
            }
        });
        
        Onended = setInterval(function(){
            const $ = window.$
            if ($('.myVideoEpiqe').length > 0) {
                document.querySelector('.myVideoEpiqe.active').onended = function(e){ 
                    const $ = window.$
                    positionText++;
                    if (positionText < $('.myVideoEpiqe').length) {
                        $('.myVideoEpiqe.active, .contentTextAnimate .context.active').next().addClass('active').prev().removeClass('active') 
                    }else{
                        $('.myVideoEpiqe:nth('+(Number($('.myVideoEpiqe').length) - 1)+'), .contentTextAnimate .context:nth('+(Number($('.myVideoEpiqe').length) - 1)+')').removeClass('active')
                        $('.myVideoEpiqe:nth(0),.contentTextAnimate .context:nth(0)').addClass('active')
                        positionText = 0;
                    }
                    document.querySelector('.myVideoEpiqe.active').play();
                    setTimeout(function(){
                        animation.restart();
                    },400)
                };
            }
        },300)
    }
    
    componentDidUpdate = () =>{
        clearInterval('Onended')
    }

    render() {
        let thes = this;
        let animation = this.fnCreateAnimation();
        const options = {
            items: 1,
            nav: false,
            singleItem:true,
            navText: [
                '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em"><path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>',
                '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em"><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>'
            ],
            loop:true,
            rewind: true,
            autoplay: true,
            autoplayTimeout: 5000
        };

        const events = {
            onChanged: function(event) {
                if(thes.esDispositivoIOS() && !window.MSStream) {
                    if (event.page.index >= 0) {
                        thes.fnShowText(animation, event.page.index)    
                    }
                }
            }
        };
        return (
            <div className="content-slider home content-slider-banner">
                <video loop={false} id="myVideoEpiqe" className="myVideoEpiqe active" poster={ScreenVideo} preload={"auto"} autoPlay={false} muted={true}>
                    <source src={video1} type="video/mp4"></source>
                </video>
                <video loop={false} id="myVideoEpiqe" className="myVideoEpiqe" preload={"auto"} autoPlay={false} muted={true}>
                    <source src={video2} type="video/mp4"></source>
                </video>
                <video loop={false} id="myVideoEpiqe" className="myVideoEpiqe" preload={"auto"} autoPlay={false} muted={true}>
                    <source src={video3} type="video/mp4"></source>
                </video>
                <video loop={false} id="myVideoEpiqe" className="myVideoEpiqe" preload={"auto"} autoPlay={false} muted={true}>
                    <source src={video4} type="video/mp4"></source>
                </video>
                <div className="contentTextAnimate" >
                    <div className="context context1 active">
                        <h1 className="ml2">Es&nbsp;momento&nbsp;de</h1>
                        <h1 className="ml2">lanzarse&nbsp;al&nbsp;cambio...</h1>
                    </div>
                    <div className="context context2">
                        <h1 className="ml2">De&nbsp;vivir&nbsp;a&nbsp;tu&nbsp;ritmo</h1>
                    </div>
                    <div className="context context3">
                        <h1 className="ml2">De&nbsp;vivir&nbsp;en&nbsp;el&nbsp;mejor&nbsp;proyecto&nbsp;de</h1>
                        <h1 className="ml2">Av.&nbsp;Olguín!&nbsp;</h1>
                    </div>
                    <div className="context context4">
                        <h1 className="ml2">A&nbsp;unos&nbsp;pasos&nbsp;del&nbsp;Jockey</h1>
                        <h1 className="ml2">Plaza</h1>
                    </div>     
                </div>
                <OwlCarousel ref="car" options={options} events={events} className="owl-carousel  myCarouselBanner owl-theme owl-loaded owl-drag">
                    <div class="content-image">
                        <a href="#">
                            <div class="image" style={{backgroundImage:`url(${ScreenVideo4})`}} />
                        </a>
                    </div>
                    <div class="content-image">
                        <a href="#">
                            <div class="image" style={{backgroundImage:`url(${ScreenVideo1})`}} />
                        </a>
                    </div>
                    <div class="content-image">
                        <a href="#">
                            <div class="image" style={{backgroundImage:`url(${ScreenVideo2})`}} />
                        </a>
                    </div>
                    <div class="content-image">
                        <a href="#">
                            <div class="image" style={{backgroundImage:`url(${ScreenVideo3})`}} />
                        </a>
                    </div>
                </OwlCarousel>
            </div>
        )
    }
}
