import React, { Component } from 'react'
import BackgroundImage from 'react-background-image-loader';

export default class RecorridoVirtual extends Component {
    constructor(props){
        super(props);
    }
  render() {
    return (
        <div id="recorrido" className="content-section-recorrido-virtual page_front">
            <h1 className="generic-green-h1">{this.props.title}<br /> {this.props.subtitle}</h1>
            <br/>
                <div className="content-slider">
                    <a href={this.props.url} target="_blank">
                        <BackgroundImage className="img-recorrido" src={this.props.image} placeholder="">
                            <img src={this.props.svg} alt="mySvg"/>
                        </BackgroundImage>
                    </a>
                </div>
        </div>
    )
  }
}
