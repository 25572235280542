import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import Loading from "../components/common/Loading"

import AdminLayout from '../components/common/layout/AdminLayout';
import PublicLayout  from '../components/common/layout/PublicLayout';
import LoginLayout from "../components/common/layout/LoginLayout"
import PromoLayout from "../components/common/layout/PromoLayout"

import privateRoutes from './privateRoutes';
import publicRoutes  from './publicRoutes';
import promoRoutes  from './promoRoutes';
import sessionRoutes from './sessionRoutes';
import { UserActions } from '../components/admin/user/UserActions';
import NotFound from '../components/common/NotFound';
import ScrollTop from "../components/other/ScrollTop"
import Dialog from '../components/web/dialog/dialog';

class Routes extends Component {

    constructor(props) {
        super(props);
        this.userActions = new UserActions(this.props.dispatch);
     }

    componentWillMount() {
        this.userActions.verify();
    }

    render() {
        const user = this.props.user;
        if (!user.verified) { return(<div>Loading...</div>); }
        return (
            <div>
            <Loading />
            <BrowserRouter>
                <ScrollTop>
                <Dialog></Dialog>
                <Switch>

                    { _.map(publicRoutes, (route, key) => {
                        const { component, path } = route;
                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={ (route) => (
                                    <PublicLayout component={component} route={route} user={user} />
                                )}
                            />
                        )
                    }) }

                    { _.map(promoRoutes, (route, key) => {
                        const { component, path } = route;
                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={ (route) => (
                                    <PromoLayout component={component} route={route} user={user} />
                                )}
                            />
                        )
                    }) }

                    { _.map(privateRoutes, (route, key) => {
                        const { component, path } = route;
                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={ (route) =>
                                    user.logged ? (
                                        <AdminLayout component={component} route={route} user={user} userActions={this.userActions} />
                                    ) : (
                                        <Redirect to="/login"/>
                                    )
                                }
                            />
                        )
                    }) }

                    { _.map(sessionRoutes, (route, key) => {
                        const { component, path } = route;
                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={ (route) =>
                                    user.logged ? (
                                        <Redirect to="/admin/dashboard"/>
                                    ) : (
                                        <LoginLayout component={component} route={route} user={user}/>
                                        )
                                    }
                                    />
                                    )
                                }) }
                    <Route component={ NotFound } />
                </Switch>
                </ScrollTop>
            </BrowserRouter>
            </div>
        );
    }
}

function mapStateToProps(state, props) { return { user: state } }
function mapDispatchToProps(dispatch) { return { dispatch }; }

export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);
