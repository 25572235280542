import React, { Component } from 'react'

export default class AdminConsideracion extends Component {
  render() {
    return (
      <div>
        <p><b>A considerar</b><br /><small>Ningún cambio es guardado sin antes presionar el botón Guardar.<br />Mover los items para ajustar el ordenamiento del item.</small></p>
        <hr />
      </div>
    )
  }
}
