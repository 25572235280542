import React, { Component } from 'react'
import * as THREE from 'three';
import * as _ from 'lodash';

const $ = window.$

export default class ParticulasStar extends Component {
    componentDidMount = () =>{
        var tau = Math.PI * 2;
        var width, height;
        var mode;
        var scene, camera, renderer, pointCloud;
        //ANIMATION CONTROLLER
        var animations = [];
        var TARGET_FRAMERATE = 60; //fps
        var frameTime = 1000 / TARGET_FRAMERATE;
        var lastTime = 0;
        var deltaTime;
        var requestAnimFrame = (function(){
            return  window.requestAnimationFrame       ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame    ||
                    function( callback ){
                    window.setTimeout(callback, 1000 / 60);
                    };
        })();
        //----------
        
        THREE.ImageUtils.crossOrigin = '';

        var SETTINGS = [{
            name: 'Space',
            particleCount: 3000,
            material: new THREE.PointCloudMaterial({ 
            size: 1, 
            color: 0xffffff,
            map:createCanvasMaterial('#ffffff', 256),
            transparent: true,
            opacity: 1
            }),
            initialize: null,
            spawnBehavior: function(index){
            var x, y, z;

            x = (Math.random() * 2000) - 1000;
            y = (Math.random() * 2000) - 1000;
            z = (Math.random() * 2000) - 1000;
            return new THREE.Vector3(x, y, z);
            },
            frameBehavior: null,
            sceneFrameBehavior: function(){
            camera.translateZ(-0.15);
            }
        }];

        function createCanvasMaterial(color, size) {
            var matCanvas = document.createElement('canvas');
            matCanvas.width = matCanvas.height = size;
            var matContext = matCanvas.getContext('2d');
            // create exture object from canvas.
            var texture = new THREE.Texture(matCanvas);
            // Draw a circle
            var center = size / 2;
            matContext.beginPath();
            matContext.arc(center, center, size/2, 0, 2 * Math.PI, false);
            matContext.closePath();
            matContext.fillStyle = color;
            matContext.fill();
            // need to set needsUpdate
            texture.needsUpdate = true;
            // return a texture made from the canvas
            return texture;
        }

        function onDocumentReady(){
            initialize();
        }

        function addModeButtons(){
            var buttons = document.getElementById('content-button-efect-star');
            _.forEach(SETTINGS, function(mode){
            var button = document.createElement('button');
            button.textContent = mode.name;
            
            button.addEventListener('click', function(){
                setMode(mode);
            });
            buttons.appendChild(button);
            });
        }

        function initialize(){
            scene = new THREE.Scene();
            camera = new THREE.PerspectiveCamera(120, width / height, 1, 1000);
            renderer = new THREE.WebGLRenderer();
            document.getElementById('content-canvas-efect-star').appendChild(renderer.domElement);
            window.addEventListener('resize', onWindowResize);
            onWindowResize();
            
            addModeButtons();
            
            scene.fog = new THREE.Fog(0, 1);
            setMode(SETTINGS[SETTINGS.length-1]);
            
            registerAnimation(render);
        }

        function setMode(_mode){
            mode = _mode;
            scene.remove(pointCloud);
            resetCamera();
            
            var points = createPoints(mode.spawnBehavior);
            var material = mode.material;
            
            pointCloud = new THREE.PointCloud(points, material);
            
            if(mode.initialize && typeof mode.initialize === 'function'){
            mode.initialize();
            }
            scene.add(pointCloud);
        }

        function createPoints(spawnBehavior){
            var ret = new THREE.Geometry();
            
            _.times(mode.particleCount, function(index){
            ret.vertices.push(spawnBehavior(index));
            })
            
            return ret;
        }
        
        function onWindowResize(){
            width = window.innerWidth;
            height = window.innerHeight;
            updateRendererSize();
        }
        
        function resetCamera(){
            camera.position.x = camera.position.y = camera.position.z = camera.rotation.x = camera.rotation.y = camera.rotation.z = 0;
        }
        
        function updateRendererSize(){
            renderer.setSize(window.innerWidth, window.innerHeight);
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        }
        
        function updateParticles(){
            if(mode.sceneFrameBehavior && typeof mode.sceneFrameBehavior === 'function'){
            mode.sceneFrameBehavior();
            }
            if(mode.frameBehavior && typeof mode.frameBehavior === 'function'){
            _.forEach(pointCloud.geometry.vertices, mode.frameBehavior);
            pointCloud.geometry.verticesNeedUpdate = true;
            pointCloud.geometry.colorsNeedUpdate = true;
            }
        }

        //--REGISTER ANIMATION
        function registerAnimation(animation){
            var wasEmpty = animations.length === 0;
            animations.push(animation);
            if(wasEmpty){
                runAnimations();
            }
        }

        function cancelAnimation(animation){
            var i = animations.indexOf(animation);
            if(i>=0){
                animations.splice(i, 1);
                console.log('Cancelled animation: %o', animation);
            }
            else{
                throw new Error('Can not cancel an animation that hasn\'t been registered!');
            }
        }

        function runAnimations(timestamp){
            if(animations.length === 0){
                return false;
            }
            else{
                requestAnimFrame(runAnimations);
                var timeElapsed;

                if(timestamp === undefined){
                    timestamp = 0;
                }

                timeElapsed = timestamp - lastTime;
                deltaTime = timeElapsed / frameTime;
                lastTime = timestamp;

                for(var i=0; i<animations.length; i++){
                    var animation = animations[i];
                    animation(deltaTime);
                }
            }
        }
        //-----------------------------
        
        function render(){
            updateParticles();
            renderer.render(scene, camera);
        }

        setInterval(() => {
            const $ = window.$
            $('#content-button-efect-star button').trigger('click')
        }, 50000);

        onDocumentReady();
    }
    render() {
        return (
        <div className="content-particles">
            <div id="content-button-efect-star" className="buttons" style={{display:'none'}}></div>
            <div id="content-canvas-efect-star">
                
            </div>
        </div>
        )
    }
}
