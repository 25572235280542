import React, { Component } from 'react'
import BackgroundImage from 'react-background-image-loader';

export default class ItemAreasSociales extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="content-image" data-categoria={this.props.categoria} onClick={this.props.evento} data-position={this.props.position} data-name={this.props.name} data-fase={this.props.fase}>
                <BackgroundImage className="myimage" data-url={this.props.image} src={this.props.image} placeholder=""/>
                <div className="text-description">
                    {this.props.legal ? '* ' + this.props.legal : ''}.
                </div>
            </div>
        )
    }
}
