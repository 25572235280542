import React, { Component } from 'react'
import FormFooter from "./FormFooter"
import { PromotionService } from '../services';
import { promotions } from '../../data/promotions';


export default class DefiPromo extends Component {
    constructor(props){
        super(props);
        this.promotionService = new PromotionService();
        this.state = {
            view:false,
            data:[],
            promo: {}
        }
    }
    componentWillMount() {
        this.setState({promo: promotions.find(p=>p.slug === this.props.slug)})

        // this.promotionService.get(this.props.slug)
        // .then((promo)=>{
        //     this.setState({promo: promo.data})
        // }).catch((error)=>{
        //     console.error(error)
        // })
        this.setState({view:true});
    }
    render() {
        if (this.state.view) {
            return (
                <div>
                    <div className="content-promotion page_front">
                        <div className="promotion-image" style={{backgroundImage: `url(${this.state.promo.image})`}}></div>
                        <div className="promotion-form">
                            <div className="container">
                                <FormFooter background="bg-white" text={["Déjanos tus datos"]}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (<div />)
        }
    }
}
