import React, { Component } from 'react'
import MediaQuery from 'react-responsive';
import { Link } from "react-router-dom"

export default class ItemBanner extends Component {
  render() {
    return (
        <div className="content-image">
            <Link to={"/promociones/"+this.props.enlace}>
            <MediaQuery query="(orientation: landscape)">
              <div className="image" style={{backgroundImage:`url(${this.props.image_desktop})`}}></div>
            </MediaQuery>
            <MediaQuery query="(orientation: portrait)">
              <div className="image" style={{backgroundImage:`url(${this.props.image_responsive})`}}></div>
            </MediaQuery>
            </Link>
        </div>
    )
  }
}
