import React, { Component } from 'react'
import ItemPromocion from "../../components/web/ItemPromocion"

export default class Promociones extends Component {
    componentDidMount = () =>{
        document.title = "Épiqe - "+this.props.title
    }

    render() {
        return(
            <div className="page_front margin-page-special content-promociones">
                <h1 className="generic-green-h1">Elige una promo</h1>
                <br/>
                <ItemPromocion />
            </div>
        )
    }
}
