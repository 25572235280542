window.dataLayer = window.dataLayer || [];

class GoogleTagManager {
    /**
     * @param {String} trackingId
     */
    constructor() {
        window.dataLayer = window.dataLayer || [];
        // this._trackingId = trackingId
    }
    init() {
        this.gtag(arguments)
    }
    gtag() {
        window.dataLayer.push(arguments)
    }
    getDataLayer(){
        return window.dataLayer
    }
}

export const gtm = new GoogleTagManager();

gtm.init('js', new Date())
// gtm.init('config', 'UA-122474095-1')
// gtm.init('config', 'UA-131356088-1')
// gtm.gtag('event', 'lead', {
//     value: 1,
//     event_label: 'lead:submit',
//     event_category: 'ecommerce'
// })

// console.log(gtm.getDataLayer())