import React, { Component } from 'react'
import SvgRespaldo from "./../../assets/svg/mapa_respaldo.svg"
import SvgCosapi from "./../../assets/svg/cosapi.svg"
import SvgGerpal from "./../../assets/svg/gerpal.svg"

export default class Respaldo extends Component {
    componentDidMount = () =>{
        document.title = "Épiqe - "+this.props.title
    }
    render() {
        return (
            <div className="page_front margin-page-special">
                <div className="fondo fondo-respaldo-burbles"></div>
                <div className="fondo_gray_cover"></div>
                <div className="content-respaldo">
                    <div className="center-respaldo">
                        <div className="row">
                            <div className="col-md-4 content-img-respaldo">
                                <img alt="myimage" src={SvgRespaldo} className="img-mapa img-fluid"/>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                                <div className="content-information">
                                    <h1 className="generic-green-h1">NUESTRA EXPERIENCIA</h1>
                                    <h2 className="generic-purple-h2">Respaldo internacional con más de 55 años<br/>de trayectoria</h2>
                                    <img alt="myimage" src={SvgCosapi} className="img-socios img-cosapi img-fluid"/>
                                    <p className="text-respaldo">Empresa inmobiliaria promotora de Cosapi SA con <b>más de 55 años en el sector de ingeniería y construcción</b> a nivel nacional e internacional, construyendo las edificaciones más emblemáticas del Perú.</p>
                                    <div className="best-place-to-live"></div>
                                    <img alt="myimage" src={SvgGerpal} className="img-socios img-fluid"/>
                                    <p className="text-respaldo">Empresa inmobiliaria con <b>más de 30 años de experiencia en Perú y Chile</b>, cuenta con amplia experiencia en proyectos de gran magnitud, habiendo desarrollado más de 1'170,000 m2 en toda su trayectoria, tanto en proyectos inmobiliarios de vivienda y oficinas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
