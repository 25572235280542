import React from 'react';
import AdminSlider from "../pages/admin/Slider"
import AdminPromotions from "../pages/admin/Promotions"
import AdminPromotionNew from "../pages/admin/PromotionNew"
import AdminPromotionUpdate from "../pages/admin/PromotionUpdate"
import AdminDashboard from "../pages/admin/Dashboard"
import AdminSliderNew from "../pages/admin/SliderNew"
import AdminSliderUpdate from "../pages/admin/SliderUpdate"
import AdminTipologias from "../pages/admin/Tipologias"
import AdminProjectModelNew from "../pages/admin/ProjectModelNew"
import AdminTipologiasUpdate from "../pages/admin/TipologiasUpdate"
import AddIndicator from "../pages/admin/AddIndicator" 

export const Post = (routes) => {
    const idPost = routes.route.match.params.idPost;
    return (<div> Post {idPost}</div>);
}

export const Slider = () =>{ return (<AdminSlider 
    title="Gestión de sliders" 
    subtitle="Selecciona el Slider el cual deseas añadir imágenes."
/>) }

export const SliderNew = (myOptionSlider) =>{
    const idSlider =  myOptionSlider.route.match.params.idSlider;
    return (<AdminSliderNew 
        idSlider={idSlider} 
    />) 
}
export const SliderUpdate = (param) =>{ 
    const imageId =  param.route.match.params.imageId;
    return (<AdminSliderUpdate imageId={imageId} />) 
}
export const Promotions = () => { return (<AdminPromotions 
    title="Gestión de promociones" 
    subtitle="Agrega, edita y elimina las siguientes promociones."
/>) }
export const PromotionNew = () => {return (<AdminPromotionNew />)}
export const PromotionUpdate = () => {return (<AdminPromotionUpdate 
    title="Gestión de promociones" 
    subtitle="Actualiza la promoción."
/>)}
export const Dashboard = () => { return (<AdminDashboard 
    title="Dashboard" 
    subtitle="Selecciona uno de los bloques para comenzar a gestionar tu sitio." 
/>) }
export const Tipologias = () => { return (<AdminTipologias 
    title="Gestión de tipologías"
    subtitle="Agrega, edita o elimina tipologías."
/>) }
export const TipologiasNew = (param) => {
    const stageId =  param.route.match.params.stageId;
    return (<AdminProjectModelNew stageId={stageId}/>) 
}
export const TipologiasUpdate = () => { return (<AdminTipologiasUpdate 
    title="Gestión de tipologías"
    subtitle="Editar tipología."
/>) }

export const addIndicator = () => { return (<AddIndicator 
    title="Gestión de Indicadores"
    subtitle="Añadir indicadores."
/>) }