import axios from 'axios'
import {
    SLIDERS_ENDPOINT
} from '../config/endpoints'

/**
 * @author Juan Lopez Tello
 * */
export class SliderService {
    constructor() {
        this.headers = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en,es;q=0.8',
                'Content-Type': `multipart/form-data;`,
              }
        }
    }
    get(slider_id = false) {
        const endpoint = slider_id ? `${SLIDERS_ENDPOINT}${slider_id}` : SLIDERS_ENDPOINT;
        return axios.get(endpoint).then(response => response.data);
    }
    create(slider) {
        return axios.post(SLIDERS_ENDPOINT, slider);
    }
    update(slider) {
        return axios.put(`${SLIDERS_ENDPOINT}${slider.id}`, slider);
    }
    getImage(image_id) {
        return axios.get(`${SLIDERS_ENDPOINT}image/${image_id}`).then(response => response.data);
    }
    addImages(slider_id, images) {
        return axios.post(`${SLIDERS_ENDPOINT}${slider_id}`, images);
    }
    updateImage(image_id, image) {
        return axios.put(`${SLIDERS_ENDPOINT}image/${image_id}`, image);
    }
    deleteSlide(image_id) {
        return axios.delete(`${SLIDERS_ENDPOINT}image/${image_id}`);
    }
}