import { 
    Post, 
    Slider, 
    SliderNew,
    SliderUpdate,
    Promotions, 
    PromotionNew, 
    PromotionUpdate,
    Dashboard,
    Tipologias,
    TipologiasNew,
    TipologiasUpdate,
    addIndicator
} from '../components/AdminComponents';
export default {
    Post:{
        component: Post,
        path: '/profile/posts/:idPost'
    },
    Slider: {
        component: Slider,
        path: '/admin/slider'
    },
    SliderNew: {
        component: SliderNew,
        path: '/admin/slider/:idSlider'
    },
    addIndicator: {
        component: addIndicator,
        path: '/admin/indicadores/'
    },
    /*SliderSvg:{
        component: SliderSvg,
        path: '/admin/slider/svg/'
    },*/
    SliderUpdate: {
        component: SliderUpdate,
        path: '/admin/slider/imagen/:imageId/editar'
    },
    Promotions: {
        component: Promotions,
        path: '/admin/promociones'
    },
    AddPromotions: {
        component: PromotionNew,
        path: '/admin/promociones/nuevo'
    },
    UpdatePromotions: {
        component: PromotionUpdate,
        path: '/admin/promociones/imagen/:imageId/editar'
    },
    Dashboard: {
        component: Dashboard,
        path: '/admin/dashboard',
    },
    Tipologias: {
        component: Tipologias,
        path: '/admin/tipologias'
    },
    TipologiasNew: {
        component: TipologiasNew,
        path: '/admin/fase/:stageId/tipologias'
    },
    TipologiasUpdate: {
        component: TipologiasUpdate,
        path: '/admin/tipologia/imagen/:tipologiaId/editar'
    },
};